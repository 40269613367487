import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let element = this.element
    $($(element).find('a.gallery-group')).magnificPopup({
      type: 'image',
      tLoading: '<div class="spinner-dots-container"><div></div></div>',
      gallery: {
        enabled: true
      }
    });
  }
}