import moment from "moment/moment";


function poll_messages() {
    if (message_polling_active)
      $.getScript('/messages/update');

    // Poll for 3 more times if the signal for stopping was sent
    if (poll_for_messages)
      last_poll_ctr = 3;

    if (last_poll_ctr > 0) {
      last_poll_ctr--;
      setTimeout(poll_messages, 10000);
    }
};


function start_message_polling() {
  poll_for_messages           = true;
  polling_enabled_immediately = true;
  message_polling_active      = true;
  if (last_poll_ctr === 0)
    poll_messages();
}


function stop_message_polling() {
  poll_for_messages = false;
}


function enable_message_polling() {
  message_polling_active = true;
}


function disable_message_polling() {
  message_polling_active = false;
}

window.polling_enabled_immediately = false;
window.poll_for_messages = false;
window.last_poll_ctr = 0;
window.last_poll_ctr = false;
window.poll_messages = poll_messages;
window.start_message_polling = start_message_polling;
window.stop_message_polling = stop_message_polling;
window.enable_message_polling = enable_message_polling;
window.disable_message_polling = disable_message_polling;