$.fn.select2.amd.require(['select2/selection/search'], function (Search) {
  let oldRemoveChoice = Search.prototype.searchRemoveChoice;

  Search.prototype.searchRemoveChoice = function () {
    oldRemoveChoice.apply(this, arguments);
    this.$search.val('');
  };
});

$.extend($.fn.select2.amd.require('select2/defaults'), {
  dropdownPosition: 'auto'
});

$.fn.select2.amd.require('select2/dropdown/attachBody').prototype._positionDropdown = function() {

  let $window = $(window);

  let isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
  let isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');

  let newDirection = null;

  let offset = this.$container.offset();

  offset.bottom = offset.top + this.$container.outerHeight(false);

  let container = {
    height: this.$container.outerHeight(false)
  };

  container.top = offset.top;
  container.bottom = offset.top + container.height;

  let dropdown = {
    height: this.$dropdown.outerHeight(false)
  };

  let viewport = {
    top: $window.scrollTop(),
    bottom: $window.scrollTop() + $window.height()
  };

  let enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
  let enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);

  let css = {
    left: offset.left,
    top: container.bottom
  };

  // Determine what the parent element is to use for calculating the offset
  let $offsetParent = this.$dropdownParent;

  // For statically positioned elements, we need to get the element
  // that is determining the offset
  if ($offsetParent.css('position') === 'static') {
    $offsetParent = $offsetParent.offsetParent();
  }

  let parentOffset = $offsetParent.offset();

  css.top -= parentOffset.top
  css.left -= parentOffset.left;

  let dropdownPositionOption = this.options.get('dropdownPosition');

  if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {

    newDirection = dropdownPositionOption;

  } else {

    if (!isCurrentlyAbove && !isCurrentlyBelow) {
      newDirection = 'below';
    }

    if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
      newDirection = 'above';
    } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
      newDirection = 'below';
    }

  }

  if (newDirection === 'above' ||
    (isCurrentlyAbove && newDirection !== 'below')) {
    css.top = container.top - parentOffset.top - dropdown.height;
  }

  if (newDirection != null) {
    this.$dropdown
      .removeClass('select2-dropdown--below select2-dropdown--above')
      .addClass('select2-dropdown--' + newDirection);
    this.$container
      .removeClass('select2-container--below select2-container--above')
      .addClass('select2-container--' + newDirection);
  }

  this.$dropdownContainer.css(css);

};

$.fn.select2.amd.define('select2/selectAllAdapter', [
  'select2/utils',
  'select2/dropdown',
  'select2/dropdown/dropdownCss',
  'select2/dropdown/attachBody'
], function (Utils, Dropdown, DropdownCss, AttachBody) {

  function SelectAll() {
  }

  SelectAll.prototype.render = function (decorated) {
    let self = this,
      $rendered = decorated.call(this),
      $selectAll = $(
        '<button class="btn btn-xs btn-outline-primary text-nowrap my-1 mx-1 px-2 px-lg-3" type="button"><i class="fa fa-check-square-o"></i> Select All</button>'
      ),
      $unselectAll = $(
        '<button class="btn btn-xs btn-outline-primary text-nowrap my-1 mx-1 px-2 px-lg-3" type="button"><i class="fa fa-square-o"></i> Deselect All</button>'
      ),
      $btnContainer = $('<div class="my-2">').append($selectAll).append($unselectAll);
    if (!self.$element.prop("multiple")) {
      // this isn't a multi-select -> don't add the buttons!
      return $rendered;
    }
    $rendered.find('.select2-dropdown').prepend($btnContainer);
    $selectAll.on('click', function (e) {
      let $results = $rendered.find(".select2-results__option[role='option']");
      $results.each(function() {
        let result = Utils.GetData($(this)[0], 'data');
        if (result) {
          if (self.$container.find('.select2-search__field').val() !== result.text && result.text.toLowerCase() !== 'all' && result.text.toLowerCase() !== 'top10') {
            self.trigger('select', {
              data: result
            });
          }
        }
      });
      self.trigger('close');
    });
    $unselectAll.on('click', function (e) {
      let selectedData = self.$element.val();
      let data = [];
      let newValues = [];
      if (selectedData) {
        if (self.$container.find('.select2-search__field').val() !== '' ) {
          let $results = $rendered.find('.select2-results__option[aria-selected=true]');
          $results.each(function() {
            let result = Utils.GetData($(this)[0], 'data');
            data.push(result.id)
          });

          newValues = selectedData.filter(function(obj) { return data.indexOf(obj) === -1; });
        }
        self.$element.val(newValues).trigger('change');
        self.$element.trigger('select2:unselect');
      }
      self.$container.find('.select2-search__field').val('').trigger('input')
      self.trigger('close');

    });
    return $rendered;
  };

  return Utils.Decorate(
    Utils.Decorate(
      Utils.Decorate(Dropdown, DropdownCss),
      AttachBody
    ),
    SelectAll
  );
});
