import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static poolsProblemRadarChart = {};
  connect() {
    let that = this;
    let element = that.element;
    let seriesData           = JSON.parse(element.dataset['seriesData']);
    let darkMode    = element.dataset['darkMode'] === 'true';
    let fillOpacity = darkMode ? .8 : .6;

    if (seriesData) {
      let dangerStart = 1000;
      let warningStart= 5000;
      let safeStart   = 9000
      let noDataStart = 11000;
      let noDataEnd   = 12000 + 500; // To allow highcharts some additional space for the bubbles(or they may be cropped)

      let containerId = element.dataset['containerId'];
      let saReportCapacityUrl  = element.dataset['saReportCapacityUrl'];
      let background_opacity= 0.5
      let safe_background= 'rgba(6, 178, 14,' + background_opacity + ')'
      let warning_background= 'rgba(255, 170, 51,' + background_opacity + ')'
      let danger_background= 'rgba(245, 73, 20,' + background_opacity + ')'
      let danger_warning_threshold= 0.383;
      let warning_safe_threshold= 0.686;
      let safe_anomaly_threshold= 0.840;
      let x_tick_interval = seriesData[0].data.length !== 0 ? (360 / seriesData[0].data.length) : 45

      that.poolsProblemRadarChart = Highcharts.chart(containerId, {
        chart: {
          polar: true,
          height: '75%'
        },
        title: {
          text: ''
        },
        plotOptions: {
          bubble: {
            minSize: 10,
            maxSize: 50,
            color: 'rgba(8, 109, 110, 1)',
            cursor: 'pointer',
            pointPlacement: 'between',
            marker: {
              fillColor: 'rgba(12, 164, 165, 1)',
              fillOpacity: fillOpacity,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: 'grey',
                  lineWidth: 2
                }
              }
            }
          },
          series: {
            stickyTracking: false,
            states: {
              hover: {
                halo: {
                  size: 0
                }
              }
            },
            point: {
              events: {
                click: function (e) {
                  let storageArraySelection = e.point.custom.storage_array_serial_number
                  let poolSelection = e.point.custom.pool_id
                  Turbo.visit(saReportCapacityUrl + "?storage_array[serial_number]=" + storageArraySelection + "#pool_" + poolSelection);
                },
                mouseOver: function (e) {
                  $(document).trigger("showStorageArray", e.target.custom.storage_array_serial_number);
                },
                mouseOut: function (e) {
                  $(document).trigger("hideStorageArray");
                }
              }
            }
          }
        },
        xAxis: {
          tickInterval: x_tick_interval,
          min: 0,
          max: 360,
          labels: {
            enabled: false
          },
          lineColor: 'white',
          gridLineColor: 'white'
        },
        yAxis: {
          labels: {
            enabled: false
          },
          tickPositions: [
            0,
            dangerStart,
            calculateMidpoint(dangerStart, warningStart),
            warningStart,
            calculateMidpoint(warningStart, safeStart),
            safeStart,
            noDataStart,
            noDataEnd
          ],
          gridLineColor: 'white'
        },
        zAxis: {
          range: 1
        },
        legend: {
          enabled: false
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{
            backgroundColor: {
              radialGradient: {cx: 0.5, cy: 0.5, r: 0.5},
              stops: [
                [0, danger_background],
                [danger_warning_threshold - 0.001, danger_background],
                [danger_warning_threshold, warning_background],
                [warning_safe_threshold - 0.001, warning_background],
                [warning_safe_threshold, safe_background],
                [safe_anomaly_threshold - 0.001, safe_background],
                [safe_anomaly_threshold, "transparent"]
              ]
            },
            borderWidth: 0
          }]
        },
        tooltip: {
          formatter: function () {
            return that.logicalTooltip(this.point);
          }
        },
        series: seriesData
      });

      $(document).on('showPools', function (event, value) {
        that.poolsProblemRadarChart.series[0].data.forEach(data_element => {
          if (data_element.custom.storage_array_serial_number === value.toString()) {
            data_element.setState('hover');
            that.poolsProblemRadarChart.tooltip.refresh(data_element);
            that.poolsProblemRadarChart.tooltip.destroy();
          }
        })
      });

      $(document).on('hidePools', function () {
        that.poolsProblemRadarChart.series[0].data.forEach(data_element => {
          data_element.setState('');
          that.poolsProblemRadarChart.tooltip.destroy();
        })
      });
    }
  }

  updateToLogicalValues(){
    let that = this;
    that.poolsProblemRadarChart.series.forEach(element => {
      element.zData = element.data.map(function(e) { return e.custom.total_logical_capa; });
      element.data.forEach(function(e) { e.y = that.calculateYValue(e.custom.logical_usage_ratio) });
    })
    that.poolsProblemRadarChart.tooltip.options.formatter = function() { return that.logicalTooltip(this.point); };
    that.poolsProblemRadarChart.render();
  }

  updateToPhysicalValues(){
    let that = this;
    that.poolsProblemRadarChart.series.forEach(element => {
      element.zData = element.data.map(function(e) { return e.custom.total_physical_capa; });
      element.data.forEach(function(e) { e.y = that.calculateYValue(e.custom.physical_usage_ratio) });
    });
    that.poolsProblemRadarChart.tooltip.options.formatter = function() { return that.physicalTooltip(this.point); };
    that.poolsProblemRadarChart.render();
  }

  physicalTooltip(point) {
    let poolsProblemRadarTexts = JSON.parse(this.element.dataset['poolsProblemRadarTexts']);
    let physical_usage_ratio_text = poolsProblemRadarTexts['physical_usage_ratio_text'];
    let total_physical_usage_text = poolsProblemRadarTexts['total_physical_usage_text'];
    let free_physical_capa_text = poolsProblemRadarTexts['free_physical_capa_text'];
    let undefined_physical_usage_ratio_text = poolsProblemRadarTexts['undefined_physical_usage_ratio_text'];
    let effective_capacity_free_text = poolsProblemRadarTexts['effective_capacity_free_text'];
    let fmc_text = poolsProblemRadarTexts['fmc_text'];
    let subscribed_cap_text = poolsProblemRadarTexts['subscribed_cap_text'];
    let sub_ratio_text = poolsProblemRadarTexts['sub_ratio_text'];

    let tooltip = '<span style="color:' + point.color + '">● </span><b>' + point.name + '</b><br/>' +
      physical_usage_ratio_text     + ': ' + point.custom.physical_usage_ratio + '%<br/>' +
      total_physical_usage_text     + ': ' + point.custom.formatted_total_physical_capa + '<br/>' +
      free_physical_capa_text       + ': ' + point.custom.free_physical_capa + '<br/>' +
      effective_capacity_free_text  + ': ' + point.custom.estimated_free_capa + '<br/>' +
      fmc_text                      + ': ' + point.custom.fmc_compression_ratio + '<br/>' +
      subscribed_cap_text           + ': ' + point.custom.subscribed_capa + '<br/>' +
      sub_ratio_text                + ': ' + point.custom.subscribed_capa_ratio + '%<br/>';
    if(point.undefined_physical_usage_ratio){
      tooltip += '<i>' + undefined_physical_usage_ratio_text + '</i>'
    }
    return tooltip;
  }

  logicalTooltip(point) {
    let poolsProblemRadarTexts = JSON.parse(this.element.dataset['poolsProblemRadarTexts']);
    let logical_usage_ratio_text = poolsProblemRadarTexts['logical_usage_ratio_text'];
    let total_logical_usage_text = poolsProblemRadarTexts['total_logical_usage_text'];
    let logical_capacity_free_text = poolsProblemRadarTexts['logical_capacity_free_text'];
    let nr_pool_vols = poolsProblemRadarTexts['nr_pool_vols'];
    let subscribed_cap_text = poolsProblemRadarTexts['subscribed_cap_text'];
    let sub_ratio_text = poolsProblemRadarTexts['sub_ratio_text'];

    return '<span style="color:' + point.color + '">● </span><b>' + point.name + '</b><br/>' +
      logical_usage_ratio_text    + ': ' + point.custom.logical_usage_ratio + '%<br/>' +
      total_logical_usage_text    + ': ' + point.custom.formatted_total_logical_capa + '<br/>' +
      logical_capacity_free_text  + ': ' + point.custom.free_logical_capa + '<br/>' +
      nr_pool_vols                + ': ' + point.custom.pool_volumes_count + '<br/>' +
      subscribed_cap_text         + ': ' + point.custom.subscribed_capa + '<br/>' +
      sub_ratio_text              + ': ' + point.custom.subscribed_capa_ratio + '%<br/>';
  }
  calculateYValue(usage_ratio) {
    return (usage_ratio < 70 ) ? (11 - 0.02857 * usage_ratio) *1000 : (27.66 - 0.2666 * usage_ratio) *1000
  }

  totalLogicalCapacity() {
    if (this.poolsProblemRadarChart)
      this.updateToLogicalValues();
  }

  totalPhysicalCapacity() {
    if (this.poolsProblemRadarChart)
      this.updateToPhysicalValues();
  }
}