import { Controller } from "@hotwired/stimulus"
import { initDetailedKpiChart } from "../custom_functions/charts/detailed_kpis/index";

export default class extends Controller  {

  connect() {
    const element = this.element;

    this.initHighChart(element)
  }

  initHighChart(element) {
    const that = this;
    const chartType = element.dataset['chartType'];


    switch (chartType) {
      case 'solidgauge':
        that.setSolidGaugeChart(element)
        break;
      case 'treemap_chart':
        that.setTreeMapChart(element);
        break;
      case 'column':
        that.setColumnChart(element);
        break;
      case 'bubble':
        that.setBubbleChart(element);
        break;
      case 'detailed_kpi':
        const meta = JSON.parse(element.dataset['meta'])
        const series = JSON.parse(element.dataset['series'])

        initDetailedKpiChart(meta, series)
        break;
      default:
        return false
    }
  }

  setSolidGaugeChart(element) {
    let container = element.dataset['container'];
    let chartTitle = element.dataset['title'] || "";
    let seriesData = JSON.parse(element.dataset['seriesData']);
    let animation = JSON.parse(element.dataset['animation']);
    let height = element.dataset['height'];
    let paneOptions = JSON.parse(element.dataset['paneOptions']);
    let yAxis = JSON.parse(element.dataset['yAxis']);
    let margin = JSON.parse(element.dataset['margin'] || null);
    let enableTooltip = JSON.parse(element.dataset['enableTooltip']);
    let enableDatalabel = JSON.parse(element.dataset['enableDatalabel']);

    Highcharts.chart(container, {
      chart: {
        type: 'solidgauge',
        backgroundColor: 'transparent',
        height: height,
        margin: margin
      },
      title: {
        text: chartTitle,
        align: 'center',
        verticalAlign: 'bottom',
        floating: true,
        style: {
          fontWeight: 'bold',
          fontSize: '12px',
          whiteSpace: 'nowrap'
        }
      },
      tooltip: {
        enabled: enableTooltip,
        backgroundColor: 'rgba(50, 50, 50, 0.85)',
        borderWidth: 0,
        shadow: false,
        valueSuffix: '%',
        formatter: function (point) {
          return  '<span style="color:' + increase_brightness(this.series.color, 50) + ';">' + this.point.name + '<br>' + this.series.name + '<span style="font-weight: bold;"> ' + this.point.y + '%</span></span>';
        },
        positioner: function (labelWidth) {
          return {
            x: ((this.chart.chartWidth - labelWidth) / 2),
            y: (this.chart.plotHeight / 2)
          };
        }
      },
      pane: paneOptions,
      yAxis: yAxis,
      plotOptions: {
        solidgauge: {
          animation: animation,
          dataLabels: {
            enabled: enableDatalabel,
            y: 25,
            borderWidth: 0,
            useHTML: true
          },
          linecap: 'round',
          stickyTracking: false
        }
      },
      series: seriesData,
      credits: { enabled: false }
    });
  }

  setTreeMapChart(element){
    let allocatedCapacity = element.dataset['allocatedCapacity'];
    let usedCapacity = element.dataset['usedCapacity'];
    let numberOfVolumes = element.dataset['numberOfVolumes'];
    let selectionFieldset = $('#' + element.dataset['selectionFieldset']);
    let clearLdevClusterDetails = $('#' + element.dataset['clearLdevClusterDetails']);
    window.treemapSeries = JSON.parse(element.dataset['seriesData'] || '[]');
    window.selectedNode = element.dataset['selectedNode'];

    let localTreemapSeries = JSON.parse(JSON.stringify(window.treemapSeries));

    if(window.selectedNode){
      setColorOfSelectedNode(localTreemapSeries);
      selectionFieldset.removeClass('d-none');
    }

    Highcharts.chart(element.getAttribute('id'), {
      title: {
        text: ''
      },
      useHTML: true,
      boost: {
        useGPUTranslations: true
      },
      loading: {
        style: {
          backgroundColor: 'transparent',
          opacity: 0.8
        }
      },
      series: [{
        events: {
          click: function (e){
            if (e.point.url){
              this.chart.showLoading();
              window.selectedNode = e.point.id;
              setColorOfSelectedNode(this.chart.series[0].data);
              selectionFieldset.removeClass('d-none');
              e.point.series.redraw();
              // clear Ldev Cluster Details cards
              clearLdevClusterDetails.html('');
              $.ajax({
                type: 'GET',
                url: e.point.url,
                dataType: 'script',
                success: function (){
                  this.chart.hideLoading();
                }.bind(this)
              });
            }
          }
        },
        colorByPoint: true,
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        animationLimit: 1000,
        turboThreshold: 0,
        data: setTreeMapValues(localTreemapSeries, 'allocated_capacity')
      }],
      tooltip: {
        backgroundColor: 'rgba(50, 50, 50, 0.85)',
        borderWidth: 0,
        shadow: false,
        formatter: function (e) {
          let tt = '<span style="font-weight:bold;color:' + e.chart.hoverPoint.color + '">' + e.chart.hoverPoint.name + '</span><br>\n' +
              '<span style="font-weight:bold;color:#fff">' + e.chart.hoverPoint.percentage_of_node_to_treemap + '</span><br>\n' +
              '<span style="font-weight:bold;color:#fff">' + allocatedCapacity + ': </span><span style="color:#fff">' + numberToHumanSize(e.chart.hoverPoint.allocated_capacity, 2) + '</span><br>\n' +
              '<span style="font-weight:bold;color:#fff">' + usedCapacity + ': </span><span style="color:#fff">' + numberToHumanSize(e.chart.hoverPoint.used_capacity, 2) + ' (' + e.chart.hoverPoint.used_percentage + ')</span><br>\n' +
              '<span style="font-weight:bold;color:#fff">' + numberOfVolumes + ': </span><span style="color:#fff">' + e.chart.hoverPoint.number_of_volumes + '</span><br>'

          if (e.chart.hoverPoint.infos) {
            for (key in e.chart.hoverPoint.infos) {
              tt = tt + "<br>\n" + '<span style="font-weight:bold;color:#fff">' + key + ': <span><span style="color:#fff">' + e.chart.hoverPoint.infos[key] + '</span>'
            }
          }
          return tt;
        }
      }
    });
  }

  setColumnChart(element){
    let that = this;
    let chartTitle = element.dataset['title'];
    let height = element.dataset['height'];
    let data = JSON.parse(element.dataset['chartData']);
    let series = data.series.map(function (s) {
      if (s['disabled_legend']) {
        return {
          ...s,
          events: {
            legendItemClick: function (e) {
              e.preventDefault()
            }
          }
        }
      } else {
        return s
      }
    })


    new Highcharts.chart(element, {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        height: height
      },
      legend: {
        itemStyle: {
          fontSize: data.legend_size
        }
      },
      colors: [...Highcharts.defaultOptions.colors],
      title: {
        text: chartTitle
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            y: -15,
            style: {
              fontSize: '16px'
            },
            formatter:function(){
              if (this.series &&  this.series.type === 'scatter' ) {
                return null;
              } else {
                if (this.percentage === false || this.percentage === undefined) {
                  return null;
                } else {
                  return this.percentage;
                }
              }
            }
          }
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                if (data.url_paths) {
                  Turbo.visit(data.url_paths[e.target.point.index])
                }
              }
            }
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(50, 50, 50, 0.85)',
        borderWidth: 0,
        shadow: false,
        shared: true,
        formatter: function () {
          let tooltip_text = '';
          if (this.series &&  this.series.type === 'scatter' ) {
            tooltip_text = '<span style="font-weight:bold;color:#fff">' + this.series.xAxis.categories[this.series.data.findIndex(s => s.x == this.x)] + '</span><br/>' +
                '<span style="color:' + increase_brightness(this.series.color, 50) + '">' + this.series.name + ':</span> ' +
                '<span style="color:#fff"> ' + number_to_human_size(this.y, 2) + '</span><br/>';
          } else {
            tooltip_text = '<span style="font-weight:bold;color:#fff">' + this.x + '</span><br/>';
            this.points.forEach((element) => {
              let color = element.series.userOptions.tooltipColor ? element.series.userOptions.tooltipColor : element.series.color

              if (!element.series.userOptions.disableTooltip){
                tooltip_text += '<span style="color:' + increase_brightness(color, 33) + '">' + element.series.name +
                    ':</span><span style="color:#fff"> ' + number_to_human_size(element.y, 2) + '</span><br/>';
              }
            })

            if (data.tooltip_extra_values){
              data.tooltip_extra_values.forEach((element) => {
                tooltip_text += '<span style="color:' + increase_brightness(element.color, 33) + '">' + element.name +
                    ':</span><span style="color:#fff"> ' + `${element.percentage}% (${number_to_human_size(element.value, 2)})` +
                    '</span><br/>';
              })
            }

          }
          return tooltip_text;
        }
      },
      xAxis: {
        categories: data.labels
      },
      yAxis: {
        title: {
          text: data.y_axis_title
        },
        labels: {
          formatter: function () {
            let value = this.value
            if (data.value_suffix) {
              return numberWithSpaces(value) + ' ' + data.value_suffix
            } else {
              // TODO: Does not work correctly.
              let label = this.axis.defaultLabelFormatter.call(this);
              let label_without_str = label.length > 1 ? label.slice(0, -1) : label;
              let new_label = numberWithSpaces(label_without_str) + ' ' + number_to_human_size(value).split(' ')[1];
              return new_label;
            }
          }
        }
      },
      series: series,
      credits: peaq_credits()
    });
  }

  setBubbleChart(element) {
    let chartXTitle = element.dataset['chartXTitle'];
    let chartYTitle = element.dataset['chartYTitle'];
    let containerId = element.dataset['containerId'];
    let xValueUnit  = element.dataset['xValueUnit'];
    let yValueUnit  = element.dataset['yValueUnit'];
    let xTooltipDescription  = element.dataset['xTooltipDescription'];
    let yTooltipDescription  = element.dataset['yTooltipDescription'];
    let seriesData = JSON.parse(element.dataset['seriesData']);

    new Highcharts.chart(containerId, {
      chart: {
        type: "bubble",
        zoomType: "xy"
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      xAxis: {
        title: {
          text: chartXTitle
        },
        type: "linear",
        min: 0.0
      },

      yAxis: {
        title: {
          text: chartYTitle
        },
        type: "linear",
        min: -2.0
      },
      tooltip: {
        formatter: function () {
          let result = '<span style="font-weight:bold; color:#fff">' + this.point.custom.tooltip_title + '</span><br/>';
          result += '<span style="font-style:italic; color:#fff">' + this.point.name + '</span><br/>'

          if(xTooltipDescription){
            result +=' <span style="color:#fff">' + xTooltipDescription + ': <span style="color:#fff; font-weight:bold;">' + this.x + ' ' + xValueUnit + '</span></span><br/>'
          }
          if(yTooltipDescription){
            result += '<span style="color:#fff">' + yTooltipDescription + ': <span style="color:#fff; font-weight:bold;">' + this.y + ' ' + yValueUnit + '</span></span><br/>'
          }

          result += '<span style="color:#fff">' + this.point.custom.text + '</span>'

          return result
        }
      },
      series: seriesData,
      credits: peaq_credits()
    })
  }

}