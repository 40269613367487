import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let that = this;
    let customer = this.element.dataset['customer'];
    let storage_array_sn = this.element.dataset['storageArraySn'];
    let disabled_submit = this.element.dataset['disabledSubmit'] === 'true';
    let run_job = this.element.dataset['runJob'] === 'true';
    let analyseButton = $('#graph_parameters_submit');

    analyseButton.attr("disabled", disabled_submit);

    $('#graph_menu_controls_' + customer + '_' + storage_array_sn + ' :input').change(function() {
      analyseButton.attr("disabled", disabled_submit);
      $('#gallery-performance-' + customer + '-' + storage_array_sn).html('');
    });
    $('#dynamic_graphs').change(function () {
      analyseButton.attr("disabled", disabled_submit);
      $('#gallery-performance-' + customer + '-' + storage_array_sn).html('');
    });

    $('[name="graph_parameters[avg]"]').change(function() {
      $('#quick-resolution-avg').val($(this).parent().text());
    });

    $('.select2-multi').each(function(index, element) {
      let $select = $(element);
      let name = $select.attr('id');

      $select.select2({
        theme: 'bootstrap-5',
        width: "auto",
        closeOnSelect: false,
        dropdownAdapter: name === 'workplaces' ? $.fn.select2.amd.require('select2/selectAllAdapter') : null,
        dropdownCssClass: name + '-dropdown multi-selector-dropdown',
        dropdownParent: $('#graph_menu_controls_' + customer + '_' + storage_array_sn)
      }).on("select2:closing", function(e) {
        if (!run_job) {
          if(window.innerWidth > 992)
            e.preventDefault();
        }
      });

      if(window.innerWidth > 992 && !run_job) {
        $select.select2("open");
      } else {
        $('.category_selector_dropdown').height('');
        $('.storage_metric_selector_dropdown').height('');
        $('.detail_selector_dropdown').height('');
      }

      // Customization of select2
      $('.' + name + '-dropdown').parent().addClass('custom-select2-container').attr('style', $('.' + name + '-dropdown').parent().attr('style') + 'z-index: 0;');

      $('.select2-multi').on('change.select2', function (e) {
        that.initCount(element);
      });

      that.initCount(element);

      // Listen for the click event on the Select2 multiple select container
      $(element).siblings('span.select2').find('.select2-selection--multiple').on('click', function () {
        // Find the search input and focus on it
        $(this).find('.select2-search__field').focus();
      });
    });



    $('.category_selector_dropdown').height($('.categories-dropdown').height());
    $('.storage_metric_selector_dropdown').height($('.instances-dropdown').height());
    $('.detail_selector_dropdown').height($('.workplaces-dropdown').height());

    $('.custom-select2-container .select2-selection').on('click', function() {
      $(this).find('input.select2-search__field').focus();
    });

    $('.select2-multi').on('select2:selecting', function(e) {
      const $select = $(this);
      const values = $select.select2('data').map( function(o) { return o.id });
      const selected_value = e.params.args.data.id;
      if (selected_value.toLowerCase() === 'all') {
        $select.val(['all']);
      } else if (selected_value.toLowerCase() === 'top10') {
        $select.val(['top10']);
      } else {
        const valuesLowerCased = $.map(values, function(n,i){return n.toLowerCase();});
        if ($.inArray('all', valuesLowerCased) >= 0) {
          values.splice( $.inArray('all', valuesLowerCased), 1 );
          $select.val(values);
        }
        if ($.inArray('top10', valuesLowerCased) >= 0) {
          values.splice( $.inArray('top10', valuesLowerCased), 1 );
          $select.val(values);
        }
      }
      const option_values = [];
      $select.find('option').each(function() {
        option_values.push($(this).val());
      });
      const selected_values = $select.select2('data').map( function(o) { return o.id });

      // Multi-selecting values with shift key pressed
      if (e.params.args.originalEvent && e.params.args.originalEvent.shiftKey) {
        var last_selected = selected_values[selected_values.length-1];
        var last_found = false;
        var selected_found = false;
        var terminate = false;
        $.each(option_values, function(index, value) {
          if (!selected_found && (last_selected === value)) { last_found = true; }
          if (!last_found && (selected_value === value)) { selected_found = true; }
          if (last_found || selected_found) {
            if (last_found && selected_value === value) { last_found = false; terminate = true; }
            if (selected_found && $.inArray(value, selected_values) !== -1) { selected_found = false; terminate = true; }
            selected_values.push(value);
          }
          if (terminate) {
            $select.val(selected_values);
            return false;
          }
        });
      }
    }).on('select2:select', function(e) {
      let $select = $(this);
      submitOnSelectWithDebounce($select, $(that.element));
    }).on('select2:unselect', function(e) {
      let $select = $(this);
      submitOnSelectWithDebounce($select, $(that.element));
    }).on('select2:unselecting', function(e) {
      let $select = $(this);
      let values = $select.select2('data').map(function(o) { return o.id });
      if (values.length === 0){
        $select.val(['all']);
      }
    });

    $(".custom-select2-container").mouseout(function () {
      // Un-highlight all options
      $('.select2-results__option').removeClass('select2-results__option--highlighted').attr("aria-selected", false);
    });


    $('#graph_menu_controls_' + customer + '_' + storage_array_sn).on('click', function() {
      that.openMenu(customer, storage_array_sn);
    });

    if (run_job) {
      analyseButton.attr('disabled', true);
      this.closeMenu(customer, storage_array_sn);
    }

    this.select2notifier = notifySelect2ToResize.bind(this)
    addEventListener("select2ScrollNotifier", this.select2notifier)
  }

  disconnect() {
    super.disconnect();

    removeEventListener('select2ScrollNotifier', this.select2notifier)
  }

  initCount(element){
    if(element.multiple){
      let dataCount = element.dataset.count || element.options.length;
      if(dataCount) {
        if ($(element).siblings('span.select2').find('.data-count').length > 0){
          $(element).siblings('span.select2').find('.data-count').html($(element).select2('data').length + '/' + dataCount)
        } else {
          let span = '<span class="data-count text-muted">' + $(element).select2('data').length + '/' + dataCount + '</span>';
          $(element).siblings('span.select2').find('.select2-selection--multiple').append(span)
        }
        let selectionRendered = $(element).siblings('span.select2').find('.select2-selection--multiple')[0];
        selectionRendered.style.paddingRight = $(element).siblings('span.select2').find('.data-count').width() + 'px';
      }
    }
  }

  graphParametersSubmitActions(event) {
    event.preventDefault();
    let customer = this.element.dataset['customer']
    let storage_array_sn = this.element.dataset['storageArraySn']
    let generating_graphs_text = event.target.dataset['generatingGraphsText']
    $('#performance-report-controls').hide();
    $('#errors').html('');
    $('#gallery-performance-' + customer + '-' + storage_array_sn).html(generating_graphs_text);
    this.closeMenu(customer, storage_array_sn);
    $(event.target).prop('disabled', true);
    let form  = $(event.target).parents('form').eq(0);

    $.ajax({
      url: form.attr('action'),
      data: form.serialize(),
      dataType: 'script'
    });
  }

  showCommandCheckboxes(event) {
    let element = event.target
    if (element.checked) {
        $('#debug').removeClass('d-none');
        $('#trace').removeClass('d-none');
    } else {
      $('#debug').addClass('d-none');
      $('#trace').addClass('d-none');
    }
  }

  checkDebug(event) {
    let element = event.target
    if (element.checked) {
      $('#debug_cb').prop('checked', true);
    }
  }

  closeMenu(customer, storage_array_sn) {
    $('#graph_menu_controls_' + customer + '_' + storage_array_sn + ' .select2-dropdown').hide();
    let selectors = $('#graph_menu_controls_' + customer + '_' + storage_array_sn + ' .select2');
    selectors.removeClass('select2-container--open');
    selectors.removeClass('select2-container--focus');
    $('#range-quick').show();
    $('#range-details').hide();
    $('.category_selector_dropdown').height('');
    $('.storage_metric_selector_dropdown').height('');
    $('.detail_selector_dropdown').height('');
  }

  openMenu(customer, storage_array_sn) {
    if (window.innerWidth > 992) {
      $('#graph_menu_controls_' + customer + '_' + storage_array_sn + ' .select2-multi').select2('open')
      $('#graph_menu_controls_' + customer + '_' + storage_array_sn + ' .multi-selector-dropdown').show();
      $('.category_selector_dropdown').height($('.categories-dropdown').height());
      $('.storage_metric_selector_dropdown').height($('.instances-dropdown').height());
      $('.detail_selector_dropdown').height($('.workplaces-dropdown').height());
    }
    else {
      $('.category_selector_dropdown').height('');
      $('.storage_metric_selector_dropdown').height('');
      $('.detail_selector_dropdown').height('');
    }
    $('#range-quick').hide();
    $('#range-details').show();
    $('#graph_parameters_submit').attr("disabled", false).removeClass('disabled');
  }

  updateTimeSelectorFactor() {
    $('#quick-datetime').val($("#graph_parameters_time_selector_factor").val() + ' ' + $("#graph_parameters_time_selector").val());
  }
}
