import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    init_datepicker();
  }

  resetValidation(event) {
    ClientSideValidations.reset(event.target.form);
  }
}
