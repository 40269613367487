import { Controller } from "@hotwired/stimulus"
import { store, DnD } from "@dflex/dnd"

// Connects to data-controller="storage-arrays-order"
export default class extends Controller {
  static targets = [ "draggableCards" ]
  static values = [ "url" ]

  connect() {
    this.dflexDnD = null

    this.draggableCardsTargets.forEach((element) => {
      store.register({ id: element.id })
    })
  }

  disconnect() {
    store.destroy()
  }

  drag(event) {
    const id = event.target.closest('.draggable-container').id
    const button = event.button

    // Avoid right mouse click and ensure id
    if (typeof button === "number" && button === 0) {
      if (id) {
        this.dflexDnD = new DnD(id, { x: event.clientX, y: event.clientY })
        document.addEventListener("mousemove", this.onMouseMove)
      }
    }
  }

  drop() {
    if(this.dflexDnD) {
      document.removeEventListener("mousemove", this.onMouseMove)

      let newPosition = this.dflexDnD.draggable.gridPlaceholder.y
      let positionUpdateUrl = this.dflexDnD.draggable.draggedDOM.getAttribute('data-url')

      this.sendNewPositionRequest(positionUpdateUrl, newPosition)
      
      this.dflexDnD.endDragging()
      this.dflexDnD = null
    }
  }

  sendNewPositionRequest(positionUpdateUrl, newPosition) {
    Turbo.visit(`${positionUpdateUrl}?position=${newPosition}`)
  }

  onMouseMove = (event) => {
    if (this.dflexDnD) {
      this.dflexDnD.dragAt(event.clientX, event.clientY)
    }
  }
}
