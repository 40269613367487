import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  updateIpRaidcom(event) {
    let value = event.target.value
    let sameAsAboveChecked = document.getElementById('storage_array_same_as_above_check').checked
    if (sameAsAboveChecked) {
      $('#storage_array_ip_raidcom').val(value).attr('readonly', true);
    } else {
      $('#storage_array_ip_raidcom').attr('readonly', false);
    }
  }
  sameAsAboveCheck(event) {
    let checked = event.target.checked

    if (checked){
      $('#storage_array_ip_raidcom').val($('#storage_array_ip_export').val()).attr('readonly', true);
    } else {
      $('#storage_array_ip_raidcom').val('').attr('readonly', false);
    }
  }


}