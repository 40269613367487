import consumer from "./consumer";

consumer.subscriptions.create("PerformanceChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    performance_analyse_poller.disable();
    // Called when there's incoming data on the websocket for this channel
    if (data.cableReady) CableReady.perform(data.operations);
    if ($('#graph_menu_controls_' + data.customer + '_' + data.storage_array_sn).length === 0 && data.engine_info)
      showFlash('info', data.engine_info, -1);
    if (data.engine_notice)
      showFlash('info', data.engine_notice, -1);
    if (data.engine_warning)
      showFlash('warning', data.engine_warning, -1);
    if (data.engine_error)
      showFlash('danger', data.engine_error, -1);
    if ($('#graph_parameters_submit').length > 0) {
      $('#loading-run-job').replaceWith(data.spinner_component);
      $('#generate-graphics .card-body, #generate-graphics .card-footer').removeClass('filter_blur');
      $('#gallery-performance-' + data.customer + '-' + data.storage_array_sn).replaceWith(data.gallery_component);
      $('#graph_menu_controls input, #graph_menu_controls select').prop('disabled', false);
      // $('#graph_parameters_submit').unbind('click').click(graph_parameters_submit_actions)
      // $('#performance-report-controls').show();
    }
    $('#performance-nav a').html(data.performance_link_text);
    $('#loading-run-job').html('');
  }
});
