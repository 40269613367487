import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static values = { crosshairSyncCharts: Array };
  connect() {
    let that = this;
    let crosshairSyncCharts = []
    $('#compare-chart').on('shown.bs.modal', function (e) {
      $(that.element).find('.item').each(function(i, itemElement){
        that.loadItem(crosshairSyncCharts, itemElement);
      })
      Highcharts.charts.forEach(function (chart) {
        var x, y;
        var this_chart = chart;
        var container = $(chart.container);

        container.mousemove(function(evt) {
          x = evt.clientX - this_chart.plotLeft - container.offset().left;
          y = evt.clientY - this_chart.plotTop - container.offset().top;
          //remove old plot line and draw new plot line (crosshair) for this chart
          let xAxis;
          Highcharts.charts.forEach(function (chart2) {
            xAxis = chart2.xAxis[0];
            xAxis.removePlotLine('myPlotLineId');
            xAxis.addPlotLine({
              value: xAxis.translate(x, true),
              width: 1,
              color: '#aaa',
              id: 'myPlotLineId'
            });
          });
        });

        chart.update({
          chart: {
            events: {
              selection: function(event) {
                crosshairSyncCharts.forEach(function (chart_data) {
                  if (event.xAxis) {
                    // New selection
                    request_chart_update(chart_data['containers'], chart_data['zoom_action'], event.xAxis[0].min, event.xAxis[0].max);
                  } else {
                    // Reset Button pressed
                    reset_zoom(chart_data['containers'], chart_data['zoom_action']);
                  }
                })
              }
            },
            zoomType: 'x'
          }
        });
      });
    });

    $('.options-box .comment-text').unbind('change').change(function() {
      var comment_id = this.id.replace(/^caption_/, '');
      var hidden_field = $('#' + comment_id);
      if (hidden_field)
        hidden_field.prop('value', this.value);
    });

    $('#gallery_overview .comment-text').unbind('change').each(function() {
      var comment_id = 'caption_' + this.id;
      var text_field = $('#' + comment_id);
      if (text_field)
        text_field.prop('value', this.value);
    });

    $('.compare-chart').on('hidden.bs.modal', function (event) {
      $(this).remove();
      cleanUpHighcharts()
    });
  }

  loadItem(crosshairSyncCharts, itemElement) {
    let item = JSON.parse(itemElement.dataset['item'])
    let chartContainers = JSON.parse(itemElement.dataset['chartContainers'])
    let chartParams = JSON.parse(itemElement.dataset['chartParams'])
    crosshairSyncCharts.push({ zoom_action: chartParams['zoom_action'], containers: chartContainers });

    $('#graph-title-' + item.uuid).html(item.graph_title);
    $(function() {
      $('#showAll_' + item.uuid).click(function() {
        jQuery.each(chartContainers, function(i, container) {
          let chart = $('#' + container).highcharts();
          jQuery.each(chart.series, function(j, series) {
            series.setVisible(true, false)
          });
          chart.redraw();
        });
      });

      $('#hideAll_' + item.uuid).click(function() {
        jQuery.each(chartContainers, function(i, container) {
          let chart = $('#' + container).highcharts();
          jQuery.each(chart.series, function(j, series) {
            series.setVisible(false, false)
          });
          chart.redraw();
        });
      });
    });
  }
}