import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  updateConfirmation(event) {
    let anomalyUrl = event.target.dataset['anomalyUrl']
    let anomalyId = event.target.dataset['anomalyId']
    let confirmation = event.target.value

    $.ajax({
      type: 'POST',
      url:  anomalyUrl,
      data: { anomaly_id: anomalyId, confirmation: confirmation },
      dataType: 'script'
    })
  }
}