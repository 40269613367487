import { Controller } from "@hotwired/stimulus"
import 'jquery-blockui'

export default class extends Controller {
    connect() {
        $(document).on("turbo:before-visit", function () {
            let chart = $('#' + this.element.dataset['chartId']).highcharts();
            if (chart && chart.loadingShown){ chart.hideLoading(); }
            this.start();
        }.bind(this));
    }

    start(){
        $.blockUI({
            message: '<div class="spinner-dots-container"><div></div></div>',
            css: {
                top: ($(window).height() - 130) / 2 + 'px',
                left: ($(window).width() - 250) / 2 + 'px',
                width: '50px',
                border: 'none',
                padding: '1px',
                opacity: 1,
                cursor: 'default',
                color: '#0CA4A5',
                zIndex: 99999,
                backgroundColor: ''
            },
            showOverlay: false
        });
        $('body').css({
            cursor: 'progress'
        });
    }

    stop(){
        $.unblockUI();
        $('body').css({ cursor: 'auto' });
    }
}