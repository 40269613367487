/**
 * This Javascript Class is in the PqStatistics gem, any changes should be make there.
 * Pq Statistics Highcharts version 5.0.1
 * JS Class that initializes an element that is passed into the constructor of the class.
 *
 * Usage:
 * import { PqStatistics } from '../pq_statistics/pq_statistics_highcharts.js'
 *
 * new PqStatistics(element)
 */
export class PqStatistics {

    constructor(element) {
        let that = this;
        let chartData = JSON.parse(element.dataset['chartData']);
        let chartGroups = chartData['chart_groups'] || [chartData['chart_group']];
        let zoomAction = chartData['zoom_action'];

        // # Default Values
        let globalTitle = chartData['title'] || ''
        let globalTimeMin = chartData['time_min']
        let globalTimeMax = chartData['time_max']
        let globalTooltipNumberToHumanSize = chartData['tooltip_number_to_human_size']
        let globalTooltipBorderRadius = chartData['tooltip_border_radius']
        let globalPrecision = chartData['precision']
        let globalYMin = chartData['y_min']
        let globalYMax = chartData['y_max']
        let globalSuffix = chartData['suffix'] || ''
        let crosshairSyncAllCharts = chartData['crosshair_sync_all_charts'] || false


        // Graph definition without time_series as JSON for JS functions
        chartGroups.forEach(function (chartGroup) {
            // TimeSeries without data as JSON for JS functions
            let time_series = that.objectWithoutProperties(chartGroup['time_series'], ['data']);

            if (chartGroup['y_axis']) chartGroup['y_axes'] = [chartGroup['y_axis']]

            let container = chartGroup['container']
            let highlightLines = chartGroup['highlight_lines']
            let sharedTooltip = chartGroup['shared_tooltip']
            let maxTooltips = chartGroup['max_tooltips']
            let tooltipDateFormat = chartGroup['tooltip_date_format']
            let backgroundColor = chartGroup['background_color']
            let fillOpacity = chartGroup['fill_opacity']
            let synchCrosshairs = chartGroup['synch_crosshairs']
            let title = chartGroup['title']
            let width = chartGroup['width']
            let height = chartGroup['height']
            let itemWidth = chartGroup['item_width']
            let limitLegendTextLength = chartGroup['limit_legend_text_length']
            let limitCutoffSide = chartGroup['limit_cutoff_side']
            let hideLegend = chartGroup['hide_legend']
            let goodLevel = chartGroup['good_level']
            let warningLevel = chartGroup['warning_level']
            let criticalLevel = chartGroup['critical_level']
            let levelReversed = chartGroup['level_reversed']
            let hideTotal = chartGroup['hide_total']
            let disableZoom = chartGroup['disable_zoom']
            let disableCredit = chartGroup['disable_credit']
            let resetZoomBtnColor = chartGroup['reset_zoom_btn_color']
            let disableXAxis = chartGroup['disable_x_axis']
            let disableYAxis = chartGroup['disable_y_axis']
            let step = chartGroup['step']
            let tooltipAddition = chartGroup['tooltip_addition']
            let dateLine = chartGroup['date_line']
            let colors = chartGroup['colors']
            let yAxes = chartGroup['y_axes']
            let series = chartGroup['time_series']

            let options = {
                boost: {
                    seriesThreshold: (series.length + 1)
                },
                chart: {
                    backgroundColor: null,
                    events: {},
                    resetZoomButton: {
                        theme: {
                            fill: 'rgba(50, 50, 50, 0.5)',
                            stroke: 'rgba(50, 50, 50, 0.5)',
                            r: 3,
                            style: {
                                color: 'white'
                            },
                            states: {
                                hover: {
                                    fill: resetZoomBtnColor,
                                    stroke: resetZoomBtnColor,
                                }
                            }
                        }
                    }
                },
                colors: colors,
                plotOptions: {
                    area: {
                        stacking: 'normal',
                        states: {
                            hover: {
                                halo: {size: 0}
                            }
                        },
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: true,
                                    radius: 5
                                }
                            }
                        },
                        shadow: false,
                        animation: false
                    },
                    arearange: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                    },
                    line: {
                        states: {
                            hover: {
                                halo: {size: 0},
                            }
                        },
                        lineWidth: 2,
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: true,
                                    radius: 5
                                }
                            }
                        },
                        shadow: false,
                        animation: false
                    }
                },
                tooltip: {
                    backgroundColor: 'rgba(50, 50, 50, 0.85)',
                    borderWidth: 0,
                    shadow: false,
                    formatter: function () {
                        let d = '<tspan style="font-size: 10px;color:#fff" x="8">' + Highcharts.dateFormat(tooltipDateFormat, this.x) + '</tspan>';
                        let s = '';
                        let total = 0;
                        let suffix;
                        let tooltip_number_to_human_size;
                        let precision;
                        let total_suffix = null;
                        let total_number_to_human_size = null;
                        let total_precision = null;
                        let print_total = false;
                        if (sharedTooltip) {
                            let tt_nr = 0;
                            jQuery.each(this.points, function (i, point) {
                                let ts = that.findTimeSeriesFromTitle(time_series, point.series.name)
                                let y_axis_nr = ts['yAxis'] || 0

                                if (yAxes && yAxes[y_axis_nr] && yAxes[y_axis_nr]['suffix']) {
                                    suffix = yAxes[y_axis_nr]['suffix'];
                                } else {
                                    suffix = chartGroup['suffix'] || globalSuffix;
                                }
                                if (total_suffix == null) total_suffix = suffix;

                                if (typeof(yAxes) == 'undefined' || typeof(yAxes[y_axis_nr]) == 'undefined' ||
                                  typeof(yAxes[y_axis_nr]['number_to_human_size']) == 'undefined') {
                                    tooltip_number_to_human_size = chartGroup['tooltip_number_to_human_size'] || globalTooltipNumberToHumanSize;
                                } else {
                                    tooltip_number_to_human_size = yAxes[y_axis_nr]['number_to_human_size'];
                                }
                                if (total_number_to_human_size == null) total_number_to_human_size = tooltip_number_to_human_size;

                                if (typeof(ts['precision']) === 'undefined') {
                                    precision = chartGroup['precision'];
                                    if (typeof(precision) === 'undefined') precision = globalPrecision;
                                } else {
                                    precision = ts['precision'];
                                }

                                if (total_precision == null)
                                    total_precision = precision;

                                let y_data = (point.series.yData[point.point.index]);
                                let additional_infos = point.point.additional_infos;

                                if (tt_nr < maxTooltips) {
                                    s += '<br/><span style="color:' + that.increaseBrightness(point.series.color, 35) + '">' + point.series.name + ': </div>';
                                    if (tooltip_number_to_human_size)
                                        if (y_data.constructor === Array)
                                            s += '<span style="font-weight:bold;color:#fff">' + that.numberToHumanSize(y_data[0], precision) + ' - ' + that.numberToHumanSize(y_data[1], precision) + ' ' + suffix + '</span>';
                                        else
                                            s += '<span style="font-weight:bold;color:#fff">' + that.numberToHumanSize(point.y, precision) + '</span>';
                                    else if (y_data.constructor === Array)
                                        s += '<span style="font-weight:bold;color:#fff">' + Highcharts.numberFormat(y_data[0], precision) + ' - ' + Highcharts.numberFormat(y_data[1], precision) + ' ' + suffix + '</span>';
                                    else
                                        s += '<span style="font-weight:bold;color:#fff">' + Highcharts.numberFormat(point.y, precision) + ' ' + suffix + '</span>';
                                }
                                if (additional_infos)
                                    s += additional_infos;
                                if (tt_nr == maxTooltips)
                                    s += '<br/>...';
                                if (point.series.stackKey) {
                                    total += point.y;
                                    print_total = true;
                                }
                                tt_nr++;
                            });
                            let t = '<br/><span style="color:#fff">Total: </span>';
                            if (total_number_to_human_size)
                                t += '<span style="font-weight:bold;color:#fff">' + that.numberToHumanSize(total, total_precision) + '</span>';
                            else
                                t += '<span style="font-weight:bold;color:#fff">' + Highcharts.numberFormat(total, total_precision) + ' ' + total_suffix + '</span>';
                            if (print_total && !hideTotal)
                                s = t + s;
                        } else {
                            let ts = that.findTimeSeriesFromTitle(time_series, this.point.series.name)
                            let y_axis_nr = ts['yAxis'] || 0

                            if (yAxes && yAxes[y_axis_nr] && yAxes[y_axis_nr]['suffix'])
                                suffix = yAxes[y_axis_nr]['suffix'];
                            else
                                suffix = chartGroup['suffix'] || globalSuffix;

                            if ((typeof(yAxes === 'undefined') || typeof(yAxes[y_axis_nr]) === 'undefined') &&
                              typeof(yAxes[y_axis_nr]['number_to_human_size']) === 'undefined')
                                tooltip_number_to_human_size = chartGroup['tooltip_number_to_human_size'] || globalTooltipNumberToHumanSize;
                            else
                                tooltip_number_to_human_size = yAxes[y_axis_nr]['number_to_human_size'];

                            if (typeof(ts['precision']) === 'undefined') {
                                precision = chartGroup['precision']
                                if (typeof (precision) === 'undefined') precision = globalPrecision;
                            } else {
                                precision = ts['precision'];
                            }

                            if (this.point.stackTotal) {
                                s += '<br/><span style="color:#fff">Total: </span>';
                                if (tooltip_number_to_human_size)
                                    s += '<span style="font-weight:bold;color:#fff">' + that.numberToHumanSize(this.point.total, precision) + '</span>';
                                else
                                    s += '<span style="font-weight:bold;color:#fff">' + Highcharts.numberFormat(this.point.total, precision) + ' ' + suffix + '</span>';
                            }
                            s += '<br/><span style="color:' + this.point.series.color + '">' + this.point.series.name + ': </span>';
                            if (tooltip_number_to_human_size)
                                s += '<span style="font-weight:bold;color:#fff">' + that.numberToHumanSize(this.point.y, precision) + '</span>';
                            else
                                s += '<span style="font-weight:bold;color:#fff">' + Highcharts.numberFormat(this.point.y, precision) + ' ' + suffix + '</span>';
                        }
                        if (tooltipAddition) s += '<br/>' + tooltipAddition;

                        return d + s;
                    }
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: yAxes.map((y_axis_values, y_axes_idx) => {
                    let y_min = y_axis_values['min'];
                    if (y_min === undefined || y_min === null) y_min = chartGroup['y_min'];
                    if (y_min === undefined || y_min === null) y_min = globalYMin;

                    let y_max = y_axis_values['max'];
                    if (y_max === undefined || y_max === null) y_max = chartGroup['y_max'];
                    if (y_max === undefined || y_max === null) y_max = globalYMax;

                    let yAxisOptions = {labels: {}}
                    if (disableYAxis) {
                        Object.assign(yAxisOptions, {
                            labels: {
                                enabled: false
                            },
                            tickLength: 0,
                            title: null,
                            min: y_min ? y_min : 0,
                            max: y_max ? y_max : 0,
                            gridLineColor: 'transparent'
                        })

                    } else {
                        if (chartGroups.at(1) && y_axes_idx === 0) {
                            if (y_axis_values['title']) {
                                // Align charts
                                Object.assign(yAxisOptions, {
                                    title: {
                                        text: y_axis_values['title'],
                                        offset: 50
                                    }
                                })
                            } else {
                                // Align charts
                                Object.assign(yAxisOptions, {
                                    title: {
                                        text: '.',
                                        offset: 40,
                                        style: {
                                            fontSize: 'xx-small',
                                            color: '#fff'
                                        }
                                    }
                                })
                            }
                        } else if (y_axis_values['title']) {
                            Object.assign(yAxisOptions, {
                                title: {
                                    text: y_axis_values['title']
                                }
                            })
                        } else {
                            Object.assign(yAxisOptions, {title: null})
                        }

                        if (y_axes_idx > 0) {
                            Object.assign(yAxisOptions, {opposite: true})
                        }

                        if (y_min !== undefined && y_min !== null) {
                            Object.assign(yAxisOptions, {min: y_min})
                        }

                        if (y_max !== undefined && y_max !== null) {
                            Object.assign(yAxisOptions, {max: y_max})
                        }

                        if (y_axis_values['tickInterval'] !== undefined && y_axis_values['tickInterval'] !== null) {
                            Object.assign(yAxisOptions, {tickInterval: y_axis_values['tickInterval']})
                        }

                        if ((warningLevel !== undefined && warningLevel) || (criticalLevel !== undefined && criticalLevel)) {
                            let warning_level = warningLevel || 0;
                            let critical_level = criticalLevel || 100;
                            Object.assign(yAxisOptions, {
                                plotBands: [{
                                    from: levelReversed ? critical_level : warning_level,
                                    to: levelReversed ? warning_level : critical_level,
                                    color: 'rgba(255, 204, 51, .2)'
                                },
                                    {
                                        from: levelReversed ? 0 : critical_level,
                                        to: levelReversed ? critical_level : 99999999999999999,
                                        color: 'rgba(255, 51, 0, .2)'
                                    }
                                ],
                            })
                        }

                        if (goodLevel !== undefined && goodLevel) {
                            let goodLevelOption = {
                                from: levelReversed ? goodLevel : 0,
                                to: levelReversed ? 100 : goodLevel,
                                color: 'rgba(0, 204, 51, .2)'
                            }

                            if (yAxisOptions.plotBands){
                                yAxisOptions.plotBands.push(goodLevelOption)
                            } else {
                                Object.assign(yAxisOptions, {
                                    plotBands: [goodLevelOption]
                                })
                            }
                        }

                        if (y_axis_values['number_to_human_size'] || chartGroup['tooltip_number_to_human_size'] || globalTooltipNumberToHumanSize) {
                            Object.assign(yAxisOptions.labels, {
                                formatter: function () {
                                    let formatted_label;
                                    let human_precision = y_axis_values['human_precision'] ? y_axis_values['human_precision'] : (y_axis_values['disable_human_magnitude'] ? 1 : 0);
                                    formatted_label = that.numberToHumanSize(this.value, human_precision);
                                    if (y_axis_values['suffix'])
                                        formatted_label = formatted_label + ' ' + y_axis_values['suffix'];
                                    return formatted_label;
                                },
                            })
                        } else {
                            if (y_axis_values['suffix']) {
                                Object.assign(yAxisOptions.labels, {
                                    format: '{value} ' + y_axis_values['suffix']
                                })
                            }
                        }
                        if (y_axis_values['color']) {
                            Object.assign(yAxisOptions, {
                                style: {
                                    color: y_axis_values['color']
                                }
                            })
                        }

                        if ((y_axis_values['number_to_human_size'] || chartGroup['tooltip_number_to_human_size'] || globalTooltipNumberToHumanSize) && !y_axis_values['disable_human_magnitude']) {
                            Object.assign(yAxisOptions, {
                                tickPositioner: function () {
                                    let normalized, i, magnitude, multiples, interval;

                                    magnitude = Math.pow(1024, Math.floor(Math.log(this.tickInterval) / Math.log(1024)));
                                    normalized = this.tickInterval / magnitude;
                                    multiples = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000];
                                    for (i = 0; i < multiples.length; i++) {
                                        interval = multiples[i];
                                        if (normalized <= (multiples[i] + (multiples[i + 1] || multiples[i])) / 2) {
                                            break;
                                        }
                                    }
                                    interval *= magnitude;

                                    return this.getLinearTickPositions(interval, this.min, this.dataMax);
                                }
                            })
                        }
                    }
                    return yAxisOptions;
                }),
                series: series
            }

            if (!disableZoom) Object.assign(options.chart, {zoomType: 'x'});

            if (backgroundColor !== 'null') Object.assign(options.chart, {plotBackgroundColor: backgroundColor});

            if (zoomAction) {
                let eventSelection = {
                    selection: function (event) {
                        if (event.xAxis) {
                            // New selection
                            let begin = event.xAxis[0].min;
                            that.requestChartUpdate(chartData, begin, event.xAxis[0].max);
                        } else {
                            // Reset Button pressed
                            $('.ui-tooltip').remove();
                            that.resetZoom(chartData);
                        }
                    }
                }
                Object.assign(options.chart.events, eventSelection)
            }

            if (width) Object.assign(options.chart, {width: width})

            if (height) {
                if (title && (chartGroup === chartData['chart_groups'][0] || title !== globalTitle)) height += 30;
                Object.assign(options.chart, {height: height})
            }

            if (title && (chartGroup === chartData['chart_groups'][0] || title !== globalTitle)) {
                Object.assign(options, {title: {text: title}})
            } else {
                Object.assign(options, {title: {text: null}})
            }

            if (disableCredit || chartGroup !== chartGroups.at(-1)) {
                Object.assign(options, {credits: {enabled: false}})
            } else {
                Object.assign(options, {credits: that.peaqCredits()})
            }

            if (step) {
                Object.assign(options.plotOptions.area, {step: step})
                Object.assign(options.plotOptions.line, {step: step})
            }

            if (fillOpacity) {
                Object.assign(options.plotOptions.area, {fillOpacity: fillOpacity})
            }

            if (highlightLines) {
                Object.assign(options.plotOptions.area.states.hover, {lineWidth: 3})
                Object.assign(options.plotOptions.line.states.hover, {lineWidth: 3})
            } else {
                Object.assign(options.plotOptions.area.states.hover, {lineWidth: 1})
                Object.assign(options.plotOptions.line.states.hover, {lineWidth: 2})
            }

            if (sharedTooltip) Object.assign(options.tooltip, {shared: true})

            if (!synchCrosshairs) Object.assign(options.tooltip, {crosshairs: true})

            if (disableXAxis || (chartGroup !== chartGroups.at(-1) && !chartData['repeat_x_axis_labels'])) {
                Object.assign(options.xAxis, {labels: {enabled: false, tickLength: 0}})
            }
            if (globalTimeMin) {
                Object.assign(options.xAxis, {min: globalTimeMin * 1000})
            }
            if (globalTimeMax) {
                Object.assign(options.xAxis, {max: globalTimeMax * 1000})
            }

            if (dateLine) {
                Object.assign(options.tooltip, {
                    plotLines: [
                        {
                            color: 'red',
                            dashStyle: 'dash',
                            value: dateLine,
                            width: 1,
                            zIndex: 4
                        }
                    ]
                })
            }

            if (hideLegend) {
                Object.assign(options, {legend: {enabled: false}})
            } else {
                Object.assign(options, {
                    legend: {
                        itemStyle: {
                            fontSize: '10px'
                        },
                        padding: 3,
                        margin: 5,
                        symbolWidth: 12,
                        itemWidth: itemWidth ? itemWidth : null,
                        borderColor: null,
                        labelFormatter: function () {
                            let name = this.name;
                            if (this.name.length > limitLegendTextLength) {
                                if (limitCutoffSide && limitCutoffSide == 'left') {
                                    name = '…' + this.name.substr(this.name.length - limitLegendTextLength + 1);
                                } else {
                                    name = this.name.substr(0, limitLegendTextLength - 1) + '…';
                                }
                            }
                            return '<span style="color: ' + chartGroup['color'] + '">' + name + '</div>';
                        }
                    }
                })
            }

            if (crosshairSyncAllCharts) {
                Object.assign(options, {
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    mouseOver: function () {
                                        that.syncCrosshair(Highcharts.charts, this, this);
                                    },
                                    mouseOut: function () {
                                        that.syncCrosshair(Highcharts.charts, this, null);
                                    }
                                }
                            }
                        }
                    }
                })
            }

            if (globalTooltipBorderRadius) {
                Object.assign(options.tooltip, { borderRadius: globalTooltipBorderRadius } )
            }

            new Highcharts.chart(container, options, function (chart) {
                if (synchCrosshairs)
                    that.syncronizeCrossHairs(chartGroups, chart);
            })
        });
    }

    // When origin & target are null, crosshair is removed
    // the target point is calculated to fix potential date mismatches in the crosshair placement
    // (crosshair would otherwise be calculated based on arbitrary coordinates in the charts)
    syncCrosshair(charts, currentChart, originPoint) {
        charts.forEach( chart => {
            if(chart !== currentChart){
                let targetPoint = null;

                if(originPoint) {
                    targetPoint = chart.series[0].points.find(obj => {
                        return obj.x === originPoint.x
                    })
                }

                chart.xAxis[0].drawCrosshair(null, targetPoint);
            }
        });
    }

    // Update chart according its received json-data
    updateChart(data) {
        // Get chart_groups
        if (data) {
            let chartGroups = data['chart_groups'];
            if (!chartGroups) {
                chartGroups = [data['chart_group']];
            }

            $.each(chartGroups, function (cg_i, cg_data) {
                // Find chart container
                let chart = Highcharts.charts.find(c => (c && c.renderTo.id) === (cg_data['container'] || data['container'] || 'chart'));
                // Fix Highchart
                chart.xAxis[0].setExtremes();
                chart.hideLoading();
                chart.xAxis[0].update({
                    min: data['time_min'] ? data['time_min'] * 1000 : null,
                    max: data['time_max'] ? data['time_max'] * 1000 : null
                });

                let range_number = 0;
                $.each(cg_data['time_series'], function (ts_i, ts_data) {
                    let data_series = [];

                    let last_element = ts_data['data'][ts_data['data'].length - 1];

                    if (last_element && last_element.hasOwnProperty('value'))
                        data_series = ts_data['data'].map(function (v) {
                            if (v)
                                return (v.value || v.value == 0 ? Number(v.value) : null);
                            else
                                return null;
                        });
                    else
                        data_series = ts_data['data'].map(function (v) {
                            return (v || v == 0 ? Number(v) : null)
                        });

                    // Update chart
                    chart.series[ts_i + range_number].update({
                        pointInterval: data['interval'] * 1000,
                        pointStart: data['start_time'] * 1000,
                        data: data_series
                    }, false);

                    // Update ranges
                    if (last_element && last_element.hasOwnProperty('min') && last_element.hasOwnProperty('max')) {
                        range_number += 1;

                        chart.series[ts_i + range_number].update({
                            pointInterval: data['interval'] * 1000,
                            pointStart: data['start_time'] * 1000,
                            data: ts_data['data'].map(function (v) {
                                if (v)
                                    return [(v.min ? Number(v.min) : null), (v.max ? Number(v.max) : null)];
                                else
                                    return null;
                            })
                        }, false);
                    }
                });

                chart.redraw();
            });
        } else {
            // Empty data. Hide loading indicator and do nothing!
            $.each(Highcharts.charts, function (ci, c) {
                if (c) {
                    c.hideLoading();
                }
            });
        }
    }

    // Make an ajax request for updating the chart
    requestChartUpdate(chart, begin_attr, end_attr) {
        let that = this;
        let begin = Math.floor(begin_attr / 1000);
        let end = Math.ceil(end_attr / 1000);
        let globalContainer = chart.container
        let resetZoomButton;
        let zoomAction = chart['zoom_action']

        $.each(chart['chart_groups'], function (cg_i, cg_data) {
            let find_chart = Highcharts.charts.find(c => (c && c.renderTo.id) === cg_data['container'] || chart['container'] || globalContainer);
            find_chart.showLoading();

            // Take care, that the resetZoomButton does only show up once.
            resetZoomButton = find_chart.resetZoomButton;
            if (begin == 0 && end == 0) {
                find_chart.resetZoomButton.hide();
                find_chart.resetZoomButton.hide();
            } else {
                if (!resetZoomButton)
                    find_chart.showResetZoom();
                else
                    find_chart.resetZoomButton.show();
            }
        });

        if (zoomAction) {
            $.ajax({
                type: "GET",
                url: zoomAction + '&begin=' + begin + '&end=' + end,
                success: that.updateChart,
                error: function (req, text, error) {
                    // Error routine
                    $("#errors").html("Reload error!");
                    $.each(chart['chart_groups'], function (cg_i, cg_data) {
                        let hide_chart = Highcharts.charts.find(c => (c && c.renderTo.id) === cg_data['container'] || chart['container'] || globalContainer);
                        if (hide_chart)
                            hide_chart.hideLoading();
                    });
                }
            });
        } else {
            $.each(chart['chart_groups'], function (cg_i, cg_data) {
                let hide_chart = Highcharts.charts.find(c => (c && c.renderTo.id) === cg_data['container'] || chart['container'] || globalContainer);
                if (hide_chart)
                    hide_chart.hideLoading();
            });
        }
    }

    // Synchronize Crosshairs
    syncronizeCrossHairs(chartGroups, chart) {
        let container = $(chart.container);
        let x;
        let y;
        let this_chart = chart;

        container.mousemove(function (evt) {
            x = evt.clientX - this_chart.plotLeft - container.offset().left;
            y = evt.clientY - this_chart.plotTop - container.offset().top;

            //remove old plot line and draw new plot line (crosshair) for this chart
            chartGroups.forEach(function (chartGroup, cg_i) {
                let chart = Highcharts.charts.find(c => (c && c.renderTo.id) === (chartGroup.container || chart.container))
                if (chart) {
                    let xAxis = chart.xAxis[0];
                    xAxis.removePlotLine('myPlotLineId');
                    xAxis.addPlotLine({
                        value: xAxis.translate(x, true),
                        width: 1,
                        color: '#aaa',
                        //dashStyle: 'dash',
                        id: 'myPlotLineId'
                    });
                }
            });
        });
    }

    // JS Function to find a chart_group according to a axis name
    findTimeSeriesFromTitle(time_series, title) {
        let my_ts = {};
        $.each(time_series, function (i, ts) {
            if (title == ts.name) {
                my_ts = ts;
            }
        });
        return my_ts;
    }

    //reset the ranges and the tickInterval to default values
    resetZoom(chart) {
        this.requestChartUpdate(chart, null, null);
    }

    objectWithoutProperties(obj, keys) {
        let target = {};
        for (let i in obj) {
            if (keys.indexOf(i) >= 0) continue;
            if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
            target[i] = obj[i];
        }
        return target;
    }

    peaqCredits() {
        return {
            href: 'http://www.peaq.ch',
            style: {
                color: '#D0D0D0',
                fontSize: '9px'
            },
            text: '© peaq GmbH'
        };
    }

    increaseBrightness(hex, percent){
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if(hex.length === 3){
            hex = hex.replace(/(.)/g, '$1$1');
        }

        let r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);

        return '#' +
          ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
          ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
          ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }

    numberToHumanSize(size, precision) {
        if(size < 1024)
            return size + ' bytes';
        else if(size < 1024.0 * 1024.0)
            return (size / 1024.0).toFixed(precision) + ' KiB'
        else if(size < 1024.0 * 1024.0 * 1024.0)
            return (size / 1024.0 / 1024.0).toFixed(precision) + ' MiB'
        else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0)
            return (size / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' GiB'
        else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0 * 1024)
            return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' TiB'
        else
            return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' PiB';
    }

}
