import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    updateIOPSChartType() {
        let element = this.element;
        let type = element.dataset['type']
        let chartId = element.dataset['chartId']
        let chart = $('#' + chartId).highcharts();
        let yAxisFormat = (type === 'normal') ? undefined : '{value}%';

        chart.update({
            plotOptions: {
                area: {
                    stacking: type
                }
            },
            yAxis: {
                labels: {
                    format: yAxisFormat
                }
            }
        });
    };

}
