import "floatthead"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    let that = this;
    this.enable_fixed_table_header(that.element);
  }

  enable_fixed_table_header(element) {
    let width = $(window).width();
    // todo Fixed header not working when screen is sized for mobile (menu changed)
    let focusElement = $(document.activeElement)
    if( width > 992 ) {
      $(element).floatThead({
        top: 60,
        responsiveContainer: function($table){
          return $table.closest(".table-responsive");
        }
      }).on("floatThead", function(e, isFloated, $floatContainer) {
        if (isFloated) {
          $('table.floatThead-table').addClass("fixedHeader-floating"); // $table
        } else {
          $('table.floatThead-table').removeClass("fixedHeader-floating");
        }
      });

      setTimeout(function () {
        $(element).trigger("reflow");
      }, 400);
      setTimeout(function () {
        focusElement.focus();
      }, 500);
    }

  }
}
