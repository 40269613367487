import { Turbo } from "@hotwired/turbo-rails"
import "./controllers"
import * as ActiveStorage from "@rails/activestorage"
import Rails from "@rails/ujs"
import "./channels"
import * as bootstrap from "bootstrap"
import "./plugins/jquery"
import "./plugins/cookie_custom"
import "jquery-ui/dist/jquery-ui"
import  "./plugins/highcharts"
import "magnific-popup"
import '@client-side-validations/client-side-validations/src'
import '@client-side-validations/simple-form/src/index.bootstrap4'
import './plugins/fontawesome-pro-6-3/all'
import './plugins/fontawesome-pro-6-3/v4-shims'
import moment from 'moment';
import LocalTime from "local-time/app/assets/javascripts/local-time"
import Masonry from "masonry-layout/masonry"
import Dropzone from "dropzone";
import PerfectScrollbar from 'perfect-scrollbar'
import Scrollbar from 'smooth-scrollbar'
import "bootstrap-notify"

window.bootstrap = bootstrap
window.Turbo = Turbo;
window.Rails = Rails;
window.Scrollbar = Scrollbar;
window.PerfectScrollbar = PerfectScrollbar;
window.moment = moment;
window.Masonry = Masonry;
ActiveStorage.start()
Rails.start()
LocalTime.start()

require("@rails/actiontext")
import './custom_functions/argon_layout'
import './custom_functions/peaq_functions'
import './custom_functions/custom_loading'
import './custom_functions/swal_function'

document.addEventListener('turbo:load', function () {
  install_jq_handlers();
  $(document).find('input[autofocus]').focus();
  Dropzone.discover();
})

document.addEventListener('turbo:frame-load', function () {
  install_jq_handlers();
})

document.addEventListener('turbo:before-visit', function () {
  cleanUpHighcharts();
})
