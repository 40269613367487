import { Controller } from "@hotwired/stimulus"
import Typed from 'typed.js';

export default class extends Controller {

  connect() {
    let element = this.element;
    let texts = JSON.parse(element.dataset['texts']);
    let typeSpeed = element.dataset['typeSpeed'] || 100;
    let loop = element.dataset['loop'] || true;

    new Typed(element, {
      strings: texts,
      typeSpeed: typeSpeed,
      loop: loop,
    });

  }
}