import { Controller } from "@hotwired/stimulus"

export default class extends Controller  {
    connect() {
        let element = this.element;
        let highlight = JSON.parse(element.dataset['highlight']);
        if (highlight) {
            element.classList.add('bg-highlight');
            setTimeout(() => {
                element.classList.remove('bg-highlight');
            }, 3000);
        }
    }
}