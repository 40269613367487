import { debounce, throttle } from 'throttle-debounce';
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    let element = this.element;
    let form = $(element)
    let inputSearch = form.find('input.search-query').eq(0)
    $(inputSearch).keyup(throttle(500, function() {
      $.get(form.attr('action'), form.serialize(), null, 'script');
    }));
  }
}