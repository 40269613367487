import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  openModal() {
    let element = this.element;
    let modalId = element.dataset['modalId'];
    let modalTitle = element.dataset['modalTitle'];
    let content = element.dataset['content'];
    $('#' + modalId + ' .modal-title').html(modalTitle);
    $('#' + modalId + ' .modal-body').html(decodeURIComponent(content));
    $('#' + modalId).modal('show');
  }

}