import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  selectStorageArray(e) {
    if (e.target.value) {
      $('#agent_assign_sa_button').removeClass('disabled');
    }
    else {
      $('#agent_assign_sa_button').addClass('disabled');
    }
  }

  toggleProxySettings(e) {
    if(e.target.checked) {
      $("#proxy_settings").removeClass("d-none");
    } else {
      $("#proxy_settings").addClass("d-none");
    }
  }
}
