import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        super.connect();
        this.zoomableCharts = []
        this.crosshairSyncCharts = []

        this.registerZoomable = this.registerZoomableChart.bind(this)
        this.notifyZoom = this.zoomChart.bind(this)
        addEventListener("registerZoomableChart", this.registerZoomable)
        addEventListener("notifyZoom", this.notifyZoom)

        this.registerCrosshairSync = this.registerCrosshairSyncChart.bind(this)
        this.notifyCrossSync = this.syncCrosshair.bind(this)
        addEventListener("registerCrosshairSyncChart", this.registerCrosshairSync)
        addEventListener("notifyCrossSync", this.notifyCrossSync)
    }

    disconnect() {
        super.disconnect();

        removeEventListener("registerZoomableChart", this.registerZoomable)
        removeEventListener("notifyZoom", this.notifyZoom)

        removeEventListener("registerCrosshairSyncChart", this.registerCrosshairSync)
        removeEventListener("notifyCrossSync", this.notifyCrossSync)
    }

    registerZoomableChart(event) {
        this.zoomableCharts.push(event.detail.chart)
    }
    zoomChart(event) {
        syncZoom(this.zoomableCharts, event.detail.event);
    }

    registerCrosshairSyncChart(event) {
        this.crosshairSyncCharts.push(event.detail.chart)
    }
    syncCrosshair(event) {
        syncCrosshair(this.crosshairSyncCharts, event.detail.chart, event.detail.crosshairData);
    }
}
