import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static performanceProblemRadarChart = {};
  connect() {
    let that = this;
    let element = that.element;
    let seriesData           = JSON.parse(element.dataset['seriesData']);
    let darkMode    = element.dataset['darkMode'] === 'true';
    let fillOpacity = darkMode ? .8 : 0.6;

    if (seriesData) {
      let dangerStart   = 1000;
      let warningStart  = 5000;
      let safeStart     = 9000
      let noDataStart = element.dataset['noDataStart'];
      let noDataEnd     = 12000 + 500; // To allow highcharts some additional space for the bubbles(or they may be cropped)
      let containerId = element.dataset['containerId'];

      let dashboardStorageArrayDetailsUrl = element.dataset['dashboardStorageArrayDetailsUrl'];

      let background_opacity = 0.5;
      let safe_background = 'rgba(6, 178, 14,' + background_opacity + ')';
      let warning_background = 'rgba(255, 170, 51,' + background_opacity + ')';
      let danger_background = 'rgba(245, 73, 20,' + background_opacity + ')';
      let danger_warning_threshold = 0.383;
      let warning_safe_threshold = 0.686;
      let safe_anomaly_threshold = 0.840;
      let x_tick_interval = seriesData[0].data.length !== 0 ? (360 / seriesData[0].data.length) : 45;
      that.performanceProblemRadarChart = Highcharts.chart(containerId, {
        chart: {
          polar: true,
          height: '75%'
        },
        title: {
          text: ''
        },
        plotOptions: {
          bubble: {
            minSize: 10,
            maxSize: 50,
            color: 'rgba(9, 122, 122, 1)',
            cursor: 'pointer',
            pointPlacement: 'between',
            marker: {
              fillColor: 'rgba(12, 164, 165, 1)',
              fillOpacity: fillOpacity,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: 'grey',
                  lineWidth: 2
                }
              }
            }
          },
          series: {
            stickyTracking: false,
            states: {
              hover: {
                halo: {
                  size: 0
                }
              }
            },
            point: {
              events: {
                click: function(e) {
                  let selection = e.point.custom.serial_number;
                  Turbo.visit(dashboardStorageArrayDetailsUrl + "?customer_id=" + e.point.custom.customer_id + "&other[serial_number]=" + selection);
                },
                mouseOver: function (e) {
                  $(document).trigger("showPools", e.target.custom.serial_number);
                },
                mouseOut: function (e) {
                  $(document).trigger("hidePools");
                }
              }
            }
          }
        },
        xAxis: {
          tickInterval: x_tick_interval,
          min: 0,
          max: 360,
          labels: {
            enabled: false
          },
          lineColor: 'white',
          gridLineColor: 'white'
        },
        yAxis: {
          labels: {
            enabled: false
          },
          tickPositions: [
            0,
            dangerStart,
            calculateMidpoint(dangerStart, warningStart),
            warningStart,
            calculateMidpoint(warningStart, safeStart),
            safeStart,
            noDataStart,
            noDataEnd
          ],
          gridLineColor: 'white'
        },
        zAxis: {
          range: 1
        },
        legend: {
          enabled: false
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{
            backgroundColor: {
              radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
              stops: [
                [0,                                 danger_background],
                [danger_warning_threshold - 0.001,  danger_background],
                [danger_warning_threshold,          warning_background],
                [warning_safe_threshold - 0.001,    warning_background],
                [warning_safe_threshold,            safe_background],
                [safe_anomaly_threshold - 0.001,    safe_background],
                [safe_anomaly_threshold,            "transparent"]
              ]
            },
            borderWidth: 0
          }]
        },
        tooltip: {
          formatter: function () {
            return that.problemRadarChartTooltip(this.point);
          },
          useHTML: true
        },
        series: seriesData
      });


      $(document).on('showStorageArray', function (event, value){
        that.performanceProblemRadarChart.series[0].data.forEach(data_element => {
          if (data_element.custom.serial_number === value.toString()) {
            data_element.setState('hover')
            that.performanceProblemRadarChart.tooltip.refresh(data_element)
          }
        })
      })
      $(document).on('hideStorageArray', function (){
        that.performanceProblemRadarChart.series[0].data.forEach(data_element => {
          data_element.setState('')
          that.performanceProblemRadarChart.tooltip.refresh(data_element)
          that.performanceProblemRadarChart.tooltip.destroy()
        })
      })
    }
  }

  problemRadarChartTooltip(point) {
    let multipleCustomers = this.element.dataset['multipleCustomers']
    let performanceProblemRadarTexts = JSON.parse(this.element.dataset['performanceProblemRadarTexts']);
    let perf_text = performanceProblemRadarTexts['perf_text'];
    let workload_text = performanceProblemRadarTexts['workload_text'];
    let perf_index_text = performanceProblemRadarTexts['perf_index_text'];
    let thresholds_text = performanceProblemRadarTexts['thresholds_text'];
    let impact_text = performanceProblemRadarTexts['impact_text'];
    let status_text = performanceProblemRadarTexts['status_text'];
    let customer_text = performanceProblemRadarTexts['customer_text'];

    let tooltip = '<span style="color:' + point.color + '">● </span><b>' + point.name + '</b><br/>';
    if (multipleCustomers) {
      tooltip += customer_text  + ': ' + point.custom.customer + '<br/>';
    }
    if(point.custom.status !== 'OK'){
      tooltip += '<span class="font-weight-bold">' + status_text      + ': ' + point.custom.status + '</span><br/>';
    }
    tooltip += perf_text        + ': ' + point.custom.performance + ' ms<br/>';
    tooltip += workload_text    + ': ' + point.custom.workload + ' MiB/s<br/>';
    tooltip += perf_index_text  + ': ' + point.custom.performance_index + ' TiB/sec&sup2;<br/>';
    tooltip += thresholds_text  + ': ' + Math.round(point.custom.thresholds) + '<br/>';
    tooltip += impact_text      + ': ' + Math.round(point.custom.impact) + '<br/>';

    return tooltip;
  }

  performance() {
    if (this.performanceProblemRadarChart) {
      let updatedSeriesData = this.performanceProblemRadarChart.series[0].data
      updatedSeriesData.map(element => {
        element.z = element.custom.performance
      })

      this.performanceProblemRadarChart.series[0].setData(updatedSeriesData)
    }
  }

  workload() {
    if (this.performanceProblemRadarChart) {
      let updatedSeriesData = this.performanceProblemRadarChart.series[0].data
      updatedSeriesData.map(element => {
        element.z = element.custom.workload;
      })

      this.performanceProblemRadarChart.series[0].setData(updatedSeriesData)
    }
  }
}