import {initAnomaliesChart} from "./anomalies_chart";
import {initThresholdsBarChart, initThresholdsHistoricChart} from "./thresholds_chart";
import {initBasicKpiChart} from "./basic_kpi_chart";
import {initIOProfileHistoricChart, initIOProfilePieChart} from "./io_profile_chart";

export function initDetailedKpiChart(meta, series) {

    switch (meta.kpi) {
        case 'anomalies':
            initAnomaliesChart(meta, series)
            break;
        case 'thresholds':
            initThresholdsHistoricChart(meta, series)
            break;
        case 'thresholds_bar':
            initThresholdsBarChart(meta, series)
            break;
        case 'io_profile':
            initIOProfileHistoricChart(meta, series)
            break;
        case 'io_profile_pie':
            initIOProfilePieChart(meta, series)
            break;
        default:
            initBasicKpiChart(meta, series)
            break;
    }
}
