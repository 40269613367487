import {Controller} from "@hotwired/stimulus"
import {sortData, updateChartSorting} from "../../custom_functions/charts/kpi_comparison_shared";

export default class extends Controller {

    connect() {
        super.connect();
        this.isSortAscending = true
        this.currentSortMetric = null

        this.meta = JSON.parse(this.element.dataset['chartMeta'])
        this.seriesData = JSON.parse(this.element.dataset['seriesData'])

        this.sortedData = sortData(this.seriesData, this.currentSortMetric, this.isSortAscending)

        this.chart = this.initKpiComparisonChart()

        if (!hasData(this.sortedData)) {
            let noDataText = this.meta.noData
            this.chart.hideNoData()
            this.chart.showNoData(noDataText)
        }
    }

    changeSorting(event) {
        if (event.params.metric !== undefined) {
            this.currentSortMetric = event.params.metric
        }
        if (event.params.ascending !== undefined) {
            this.isSortAscending = event.params.ascending
        }

        updateChartSorting(this.chart, this.seriesData, this.currentSortMetric, this.isSortAscending)
    }

    initKpiComparisonChart() {
        const containerId = this.element.dataset['containerId']
        const tooltipFormatter = this.kpiComparisonTooltip
        const tooltipData = this.meta.tooltip

        const chartOptions = {
            chart: {
                type: 'column',
                events: {
                    load: function () {
                        if (this.series[0].data.length > paginationOptions.defaultSize) {
                            this.xAxis[0].update({min: 0, max: set_columns(paginationOptions.defaultSize)})
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return tooltipFormatter(this, tooltipData)
                },
                useHTML: true,
                shared: true
            },
            plotOptions: {
                series: {
                    events: {
                        show: eventToggleAxisVisibility,
                        hide: eventToggleAxisVisibility
                    }
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    autoRotation: [0, -90]
                }
            },
            yAxis: this.meta.yAxis,
            series: this.sortedData,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                    // Make the labels less space demanding on mobile
                    chartOptions: {
                        yAxis: hiddenAxisData(4)
                    }
                }]
            }
        }

        return Highcharts.chart(containerId, chartOptions);
    }

    kpiComparisonTooltip(point, tooltipData) {
        let pointData = point.points[0].point
        let tooltip = '<span style="color: #06A50C">● </span><b>' + pointData.name + '</b><br/>'

        tooltip += `<span class='font-weight-bold'>${tooltipData.iops.name}:</span> ` + numberWithSeparator(Math.round(pointData.custom[tooltipData.iops.metric])) + ` [${tooltipData.iops.unit}] <br/>`
        tooltip += `<span class='font-weight-bold'>${tooltipData.tput.name}:</span> ` + numberWithSeparator(pointData.custom[tooltipData.tput.metric]) + ` [${tooltipData.tput.unit}] <br/>`
        tooltip += `<span class='font-weight-bold'>${tooltipData.wtim.name}:</span> ` + pointData.custom[tooltipData.wtim.metric] + ` [${tooltipData.wtim.unit}] <br/>`
        tooltip += `<span class='font-weight-bold'>${tooltipData.svct.name}:</span> ` + pointData.custom[tooltipData.svct.metric] + ` [${tooltipData.svct.unit}] <br/>`

        return tooltip
    }


}
