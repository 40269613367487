import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    super.connect();

    this.eventListeners = [];

    const elements = this.element.querySelectorAll('.email-report-switch');
    elements.forEach(element => {
      const listener = this.preventDefaultAction.bind(this);
      element.addEventListener('click', listener);
      this.eventListeners.push({ element, listener });
    });
  }

  disconnect() {
    super.disconnect();

    this.eventListeners.forEach(({ element, listener }) => {
      element.removeEventListener('click', listener);
    });
    this.eventListeners = [];
  }

  preventDefaultAction(event){
    event.preventDefault();
  }

  sendRequest(event) {
    let requestData = event.target.dataset;
    let inputValue  = event.target.checked;

    $.ajax({
      type: requestData['type'],
      url:  requestData['url'],
      data: {
        type: requestData['reportType'],
        value: inputValue,
        destination: requestData['destination']
      },
      dataType: 'script'
    });
  }

}
