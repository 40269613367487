// Prototype method used to color the legend with a different color that the series,
// if the legendColor option is present on the series hash.
(function(H) {
  H.wrap(H.Legend.prototype, 'colorizeItem', function(proceed, item, visible) {
    let color = item.color;

    if(item.options.legendColor) {
      item.color = item.options.legendColor;
    }
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    item.color = color;
  });
}(Highcharts));

// Add more default colors for highcharts
Highcharts.setOptions({
  colors: ["#7cb5ec", "#adadb3", "#90ed7d", "#f7a35c",
           "#8085e9", "#f15c80", "#e4d354", "#2b908f",
           "#f45b5b", "#91e8e1", '#058DC7', '#50B432',
           '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
           '#FF9655', '#FFF263', '#6AF9C4', '#9d9dd1'],
  chart: {
    style: {
      fontFamily: ['Open Sans', 'sans-serif']
    }
  },
  credits: {
    enabled: true,
    href: "http://www.peaq.ch",
    text: "© peaq GmbH",
    style: {
    color: "#D0D0D0",
      fontSize: "9px"
    }
  },
  tooltip: {
    backgroundColor: 'rgba(50, 50, 50, 0.85)',
      style: {
      color: '#fff'
    },
    borderWidth: 0,
    borderRadius: 16,
    shadow: false
  },
  plotOptions: {
    area: {
      fillOpacity: 1
    }
  }
});

// Function to fix the chart height. To be used on the chart: events: load function
// Will set the height option to the chart only, instead of the chart + legend.
// See: https://stackoverflow.com/questions/55343675/highcharts-chart-height-apart-from-legend
function addLegendHeight(chart) {
  let legendSpace = chart.legend.legendHeight -  chart.legend.padding;

  if (legendSpace) {
    chart.setSize(null, chart.chartHeight + legendSpace, false);
  }
}

function calculateZoomExtremes(event) {
  if (event.xAxis) {
    let eventMin = event.xAxis[0].min / 1000
    let eventMax = event.xAxis[0].max / 1000

    event.xAxis[0].min = moment.unix(eventMin).subtract(12, 'hours').unix()*1000;
    event.xAxis[0].max = moment.unix(eventMax).add(12, 'hours').unix()*1000;
  }

  return event
}

function syncZoom(zoomableCharts, event) {
  let min = null
  let max = null

  if (event.xAxis) {
    min = event.xAxis[0].min
    max = event.xAxis[0].max
  }

  zoomableCharts.forEach(chart => {
    if(chart !== event.target){
      chart.xAxis[0].setExtremes(min, max);
      toggleResetButton(chart, min, max);
    }
  });
}

function toggleResetButton(chart, min, max) {
  const resetButtonShown = chart.resetZoomButton
  const shouldShowResetZoom = min !== null && max !== null

  if (shouldShowResetZoom && !resetButtonShown) {
    chart.showResetZoom();
  } else if(!shouldShowResetZoom) {
    try {
      // chart.resetZoomButton.hide();
      chart.resetZoomButton.destroy();
      delete chart.resetZoomButton;
    } catch {}
  }
}

// When origin & target are null, crosshair is removed
// the target point is calculated to fix potential date mismatches in the crosshair placement
// (crosshair would otherwise be calculated based on arbitrary coordinates in the charts)
function syncCrosshair(charts, currentChart, originPoint) {
  charts.forEach( chart => {
    if(chart !== currentChart){
      let targetPoint = null;

      if(originPoint) {
        targetPoint = chart.series[0].points.find(obj => {
          return obj.x === originPoint.x
        })
      }

      chart.xAxis[0].drawCrosshair(null, targetPoint);
    }
  });
}

function hiddenAxisData(numberOfAxes) {
  // This is used for configuring the responsive option on Highcharts,
  // it is advised to set the data assigned in the singleAxisData,
  // in the general options of the chart.
  // If not, the chart will have no data to default to when the responsive options do not apply.
  let singleAxisData =
    {
      title: {
        text: ''
      },
      labels: {
        enabled: false
      }
    }
  if(numberOfAxes === 1) {
    return singleAxisData
  } else {
    return Array(numberOfAxes).fill(singleAxisData)
  }
}

// Pagination START
var paginationOptions = {
  defaultSize: 10,
  stepWidth: 10
}

function set_columns(size){
  if (paginationOptions.defaultSize) {
    return (size < paginationOptions.defaultSize ? size : paginationOptions.defaultSize) - 1
  } else {
    return size - 1
  }
}

function calcMaxLength(chart, optionalLength = undefined) {
  return optionalLength === undefined ? chart.series[0].data.length - 1 : optionalLength
}

function calcStepWidth(stepWidth = undefined) {
  return stepWidth === undefined ? paginationOptions.stepWidth : stepWidth
}

function setExtremes(chart, min, max) {
  chart.xAxis[0].setExtremes(min, max)
}

function chartPaginationFirst(chart, maxLength = undefined) {
  const calculatedMaxLength = calcMaxLength(chart, maxLength)

  chart.xAxis[0].setExtremes(0, set_columns(calculatedMaxLength))
}

function chartPaginationLast(chart, stepWidth = undefined, maxLength = undefined) {
  const calculatedStepWidth = calcStepWidth(stepWidth)
  const calculatedMaxLength = calcMaxLength(chart, maxLength)
  const min = calculatedMaxLength - calculatedStepWidth + 1

  setExtremes(chart, min, calculatedMaxLength)
}

function chartPaginationPrevious(chart, stepWidth = undefined, maxLength = undefined) {
  const calculatedStepWidth = calcStepWidth(stepWidth)
  const calculatedMaxLength = calcMaxLength(chart, maxLength)

  const currentMin = chart.xAxis[0].getExtremes().min
  const currentMax = chart.xAxis[0].getExtremes().max

  let min, max
  if (currentMin <= 0) {
    min = 0
    max = set_columns(calculatedMaxLength)
  } else {
    min = currentMin - calculatedStepWidth < 0 ? 0 : currentMin - calculatedStepWidth
    max = (currentMax - calculatedStepWidth) < currentMin ? (currentMin - 1) : currentMax - calculatedStepWidth
  }

  setExtremes(chart, min, max)
}

function chartPaginationNext(chart, stepWidth = undefined, maxLength = undefined) {
  const calculatedStepWidth = calcStepWidth(stepWidth)
  const calculatedMaxLength = calcMaxLength(chart, maxLength)

  const currentMax = chart.xAxis[0].getExtremes().max

  if (currentMax < calculatedMaxLength ) {
    const max = calculatedMaxLength < (currentMax + calculatedStepWidth) ? calculatedMaxLength : currentMax + calculatedStepWidth
    const min = max - calculatedStepWidth + 1

    setExtremes(chart, min, max)
  }
}

function chartPaginationShowAll(chart, maxLength = undefined) {
  const calculatedMaxLength = calcMaxLength(chart, maxLength)

  setExtremes(chart, 0, calculatedMaxLength)
}
// Pagination END

// Function to show/hide yAxis when the series is deselected(hidden)
let eventToggleAxisVisibility = function(event) {
  let series  = event.target
  let yAxis   = series.yAxis

  if (event.type === "show") {
    toggleAxisVisibility(yAxis, true)
  } else if (event.type === "hide"){
    toggleAxisVisibility(yAxis, false)
  }
}

function toggleAxisVisibility(yAxis, visible) {
  if(Array.isArray(yAxis)){
    yAxis.forEach(seriesYAxis => {
      if(seriesYAxis.min === undefined && seriesYAxis.max === undefined){
        // Prevents yAxis title from becoming visible when the chart is zoomed out with a paginator
        return
      }

      seriesYAxis.update({visible: visible})
    })
  } else {
    let xAxis = yAxis.chart.xAxis[0]

    if(xAxis.dataMax === null && xAxis.old.userMax === xAxis.old.max ){
      // Prevents yAxis becoming visible after all series were hidden
      return
    }

    if(xAxis.old.userMax === xAxis.dataMax) {
      // Prevents yAxis from becoming visible when the chart is zoomed out with a paginator
      return
    }

    yAxis.update({visible: visible})
  }
}

function cleanUpHighcharts(){
  let charts = Highcharts.charts.slice()
  charts.forEach(function (chart) {
    Highcharts.charts.shift()
  })
}

function calculateMidpoint(number1, number2) {
  let highNumber, lowNumber;
  if(number1 > number2) {
    highNumber  = number1;
    lowNumber   = number2;
  } else {
    highNumber  = number2;
    lowNumber   = number1;
  }

  return (highNumber + lowNumber) / 2;
}

function peaq_credits(){
  return {
    href: 'http://www.peaq.ch',
    style: {
      color: '#D0D0D0',
      fontSize: '9px'
    },
    text: '© peaq GmbH' };
}

function numberToHumanSize(size, precision) {
  if (size < 1024)
    return size + ' bytes';
  else if(size < 1024.0 * 1024.0)
    return (size / 1024.0).toFixed(precision) + ' KiB'
  else if(size < 1024.0 * 1024.0 * 1024.0)
    return (size / 1024.0 / 1024.0).toFixed(precision) + ' MiB'
  else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0)
    return (size / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' GiB'
  else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0 * 1024)
    return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' TiB'
  else
    return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' PiB';
}

function setColorOfSelectedNode(treemap_data){
  treemap_data.forEach((point)=> {

    if (point.id == String(window.selectedNode)){
      point.color = window.darkMode ? '#595c4f' : '#919581';
    }
    else{
      point.color = window.darkMode ? '#777777' : '#D3D3D3';
    }
  });
}

function setTreeMapValues(treemap_data, type){
  treemap_data.forEach(function (data){
    data.value = data[type]
  });
  return treemap_data;
}

function request_chart_update(containers, zoomAction, beginAttr, endAttr){
  let begin = Math.floor(beginAttr / 1000);
  let end = Math.ceil(endAttr / 1000);
  let resetZoomButton;
  $.each(containers, function (cg_i, container) {
    let chart = Highcharts.charts.find(c => (c && c.renderTo.id) === container);
    chart.showLoading();

    // Take care, that the resetZoomButton does only show up once.
    resetZoomButton = chart.resetZoomButton;
    if (begin == 0 && end == 0) {
      chart.resetZoomButton.hide();
    } else {
      if (!resetZoomButton)
        chart.showResetZoom();
      else
        chart.resetZoomButton.show();
    }
  });

  if (zoomAction){
    $.ajax({
      type: "GET",
      url: zoomAction + '&begin=' + begin + '&end=' + end,
      success: update_chart,
      error: function (req, text, error) {
        // Error routine
        $("#errors").html("Reload error!");
        $.each(containers, function (cg_i, container) {
          let chart = Highcharts.charts.find(c => (c && c.renderTo.id) === container);
          chart.hideLoading();
        });
      }
    });
  }
  else {
    $.each(containers, function (cg_i, container) {
      let chart = Highcharts.charts.find(c => (c && c.renderTo.id) === container);
      chart.hideLoading();
    });
  }
}

function reset_zoom(containers, zoom_action) {
  request_chart_update(containers, zoom_action, null, null);
}

function update_chart(data){
  let chart;

  // Get chart_groups
  if (data) {
    let chartGroups = data['chart_groups'];
    if (!chartGroups) {
      chartGroups = [data['chart_group']];
    }

    $.each(chartGroups, function (cg_i, cg_data) {
      // Find chart container

      chart = Highcharts.charts.find(c => (c && c.renderTo.id) === (cg_data['container'] || data['container'] || 'chart'));

      // Fix Highchart
      chart.xAxis[0].setExtremes();
      chart.hideLoading();
      chart.xAxis[0].update({min: data['time_min'] ? data['time_min'] * 1000 : null, max: data['time_max'] ? data['time_max'] * 1000 : null });

      let range_number = 0;
      $.each(cg_data['time_series'], function (ts_i, ts_data) {
        let data_series = [];
        let last_element = ts_data['data'][ts_data['data'].length - 1];

        if (last_element && last_element.hasOwnProperty('value'))
          data_series = ts_data['data'].map(function (v) {
            if (v)
              return (v.value || v.value == 0 ? Number(v.value) : null);
            else
              return null;
          });
        else
          data_series = ts_data['data'].map(function (v) {
            return (v || v == 0 ? Number(v) : null)
          });

        // Update chart
        chart.series[ts_i + range_number].update({
          pointInterval: data['interval'] * 1000,
          pointStart: data['start_time'] * 1000,
          data: data_series
        }, false);

        // Update ranges
        if (last_element && last_element.hasOwnProperty('min') && last_element.hasOwnProperty('max')) {
          range_number += 1;

          chart.series[ts_i + range_number].update({
            pointInterval: data['interval'] * 1000,
            pointStart: data['start_time'] * 1000,
            data: ts_data['data'].map(function (v) {
              if (v)
                return [(v.min ? Number(v.min) : null), (v.max ? Number(v.max) : null)];
              else
                return null;
            })
          }, false);
        }
      });

      chart.redraw();
    });
  } else {
    // Empty data. Hide loading indicator and do nothing!
    $.each(Highcharts.charts, function (ci, c) {
      if (c) {
        c.hideLoading();
      }
    });
  }
}

function hasData(seriesData) {
  let hasData = false
  seriesData.forEach((series) => {
    series.data.forEach((element) => {
      if (element.y !== null) {
        hasData = true
      }
    })
  })

  return hasData
}

window.addLegendHeight = addLegendHeight;
window.syncZoom = syncZoom;
window.calculateZoomExtremes = calculateZoomExtremes;
window.toggleResetButton = toggleResetButton;
window.syncCrosshair = syncCrosshair;
window.hasData = hasData;
window.hiddenAxisData = hiddenAxisData;
window.set_columns = set_columns;
window.calcMaxLength = calcMaxLength;
window.calcStepWidth = calcStepWidth;
window.setExtremes = setExtremes;
window.chartPaginationFirst = chartPaginationFirst;
window.chartPaginationLast = chartPaginationLast;
window.chartPaginationPrevious = chartPaginationPrevious;
window.chartPaginationNext = chartPaginationNext;
window.chartPaginationShowAll = chartPaginationShowAll;
window.eventToggleAxisVisibility = eventToggleAxisVisibility;
window.toggleAxisVisibility = toggleAxisVisibility;
window.cleanUpHighcharts = cleanUpHighcharts;
window.calculateMidpoint = calculateMidpoint;
window.peaq_credits = peaq_credits;
window.numberToHumanSize = numberToHumanSize;
window.paginationOptions = paginationOptions;
// Functions and global variables used for treemap
window.selectedNode = null;
window.darkMode = null;
window.treemapSeries = null;
window.setColorOfSelectedNode = setColorOfSelectedNode;
window.setTreeMapValues = setTreeMapValues;
window.request_chart_update = request_chart_update;
window.update_chart = update_chart;
window.reset_zoom = reset_zoom;