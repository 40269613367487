import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let element  = this.element;

    element.addEventListener('shown.bs.modal', function (event) {
      $('form[data-client-side-validations]').enableClientSideValidations();
      $(element).find('input[autofocus]').focus()
    });
  }
}