import { Controller } from "@hotwired/stimulus"
import "../plugins/jquery"
import '../plugins/datatables';
import ScrollHint from 'scroll-hint';
import { FixedScrollbarContainer } from "../custom_functions/fixed_scrollbar_container";
import { DatatableInit } from "../custom_functions/datatable_init";
export default class extends Controller {
  static targets = ["simpleTable"]

  connect() {
    let that = this;
    if(this.hasSimpleTableTarget) {
      this.initSimpleDatatable();
    }

    if(that.element.classList.contains('datatable-filter')) {
      new DatatableInit(that.element)
    }
    if(that.element.classList.contains('table-scroll-hint')) {
      this.initScrollHint(that.element)
    }

    if(that.element.classList.contains('fixed-scrollbar-container')) {
      new FixedScrollbarContainer(that.element)
    }
  }

  initScrollHint(element) {
    let obj = element.classList.contains('datatable-filter') ? $(element).find('.dataTables_scrollBody') : $(element)
    new ScrollHint(obj, {
      suggestiveShadow: true,
      remainingTime: 0
    });
  }

  initSimpleDatatable() {
    $(this.simpleTableTarget).DataTable({
      retrieve:     true,
      searching:    false,
      info:         false,
      pagingType:   "numbers",
      lengthChange: false
    });
  }


}