import "../plugins/jquery"
import "daterangepicker"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    connect() {
        this.initDateRangePicker(this.element)
    }

    initDateRangePicker(element) {
        let that = this;
        moment.updateLocale('en', {
            week: {
                dow: 1 //Monday
            }
        });

        let input = element.querySelector('#date-before')
        let autoApply = element.dataset['autoApply'] || true
        let autoUpdateInput = input.dataset['autoUpdateInput'] || false
        let selectedDate = input.dataset['selectedDate'] || false
        let options = {
            startDate: selectedDate,
            autoApply: autoApply,
            autoUpdateInput: autoUpdateInput,
            singleDatePicker: true,
            timePicker: false,
            cancelButtonClasses: 'm-0 w-100',
            ranges: {},
            showCustomRangeLabel: false,
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Clear'
            }
        }

        $(input).daterangepicker(options).on('apply.daterangepicker', function(event, picker) {
            $(input).val(picker.startDate.format("DD.MM.YYYY"));
            that.clearMonthsBefore();
        });
    }

    clearDateBefore() {
        $('#date-before').val('');
    }

    clearMonthsBefore() {
        $('#months-before').val('');
    }
}