export function updateChartSorting(chart, seriesData, metric, isSortAscending) {
    let newSeriesData = sortData(seriesData, metric, isSortAscending)
    chart.update({series: newSeriesData})

    chart.render()
}

export function sortData(seriesData, metric, isSortAscending) {
    let sorted = JSON.parse(JSON.stringify(seriesData));
    if (metric === null) {
        metric = 'default'
    }

    sorted.forEach((series, index) => {
        sorted[index].data = series.data.sort((item1, item2) => sortKpiComparison(item1, item2, metric, isSortAscending))
    })

    return sorted
}

export function sortKpiComparison(item1, item2, metric, isSortAscending) {
    if (isSortAscending) {
        return item1.custom[metric] - item2.custom[metric]
    } else {
        return item2.custom[metric] - item1.custom[metric]
    }
}