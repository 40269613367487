import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        super.connect();

        window.darkMode = this.element.dataset['darkMode'] === 'true'
    }

    changeToAllocatedValues(event){
        let treemap_chart = $('#' + this.element.dataset['treemapId']).highcharts();

        treemap_chart.showLoading();
        treemap_chart.series[0].setData(setTreeMapValues(window.treemapSeries, 'allocated_capacity'));

        if (window.selectedNode != null){
            setColorOfSelectedNode(treemap_chart.series[0].data);
        }

        treemap_chart.series[0].redraw();
        treemap_chart.hideLoading();
    }

    changeToUsedValues(event){
        let treemap_chart = $('#' + this.element.dataset['treemapId']).highcharts();

        treemap_chart.showLoading();
        treemap_chart.series[0].setData(setTreeMapValues(window.treemapSeries, 'used_capacity'));

        if (window.selectedNode != null){
            setColorOfSelectedNode(treemap_chart.series[0].data);
        }
        treemap_chart.series[0].redraw();
        treemap_chart.hideLoading();
    }

    clearSelectionButton(event){
        let clearSelectionFieldset = $('#' + this.element.dataset['clearSelectionFieldset']);
        let treemap_chart = $('#' + this.element.dataset['treemapId']).highcharts();
        let url = this.element.dataset['clearSelectionUrl'];
        let total_capacity_label = $('#' + event.target.dataset['totalCapacityLabel']);
        let used_capacity_label = $('#' + event.target.dataset['usedCapacityLabel']);

        treemap_chart.showLoading();
        $('#report_for_selected_ldev_cluster').html('');
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'script'
        });

        window.selectedNode = null;
        clearSelectionFieldset.addClass('d-none');

        if (total_capacity_label.hasClass('active')){
            treemap_chart.series[0].setData(setTreeMapValues(window.treemapSeries, 'allocated_capacity'));
        }

        if (used_capacity_label.hasClass('active')){
            treemap_chart.series[0].setData(setTreeMapValues(window.treemapSeries, 'used_capacity'));
        }

        treemap_chart.series[0].redraw();
        treemap_chart.hideLoading();
    };

    capacitySelectorFade(event){
        let clickedTabId = event.target.dataset['clickedTab'] || event.target.closest('[data-clicked-tab]').dataset['clickedTab'];
        let capacitySelector = $('#' + this.element.dataset['capacitySelector']);

        if (clickedTabId.includes('treemap')) {
            capacitySelector.fadeIn();
        }else {
            capacitySelector.fadeOut();
        }
    }
}