import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let displayCached = !!this.element.dataset['displayCached'];
    let performanceFormData = $("#performance_menu_form").serializeArray();
    performanceFormData.push({ name: "display_cached", value: displayCached });
    $.ajax({
      type: "GET",
      url: "",
      data: performanceFormData,
      dataType: "script" })
  }
}