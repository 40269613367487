import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let runJob = this.element.dataset['runJob'] === 'true';
    if (runJob) {
      $('#generate-graphics .card-body, #generate-graphics .card-footer').addClass('filter_blur');
    } else {
      $('#generate-graphics .card-body, #generate-graphics .card-footer').removeClass('filter_blur');
    }
  }
}