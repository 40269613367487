import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = [ "nameInput", "managedCustomers" ]

    enable2FA(event) {
        if (event.target.checked)
            $('.qr-code-secret-key').fadeIn('fast');
        else
            $('.qr-code-secret-key').fadeOut('fast');
    }

    updateName(event) {
        const email = event.target.value;
        if (email.length > 0) {
            const fullName = email.split('@')[0].split('.');
            let userName = capitalize(fullName[0]);
            if (fullName.length > 1) {
                userName += ' ' + capitalize(fullName[ fullName.length - 1 ]);
            }
            const userNameInputElement = this.nameInputTarget;
            if (userNameInputElement && userNameInputElement.value.length === 0) {
                userNameInputElement.value = userName
            }
        }
    }

    displayManagedCustomers(event) {
        let selectedValues = $(event.target).val()

        if (selectedValues.includes('customer_admin') || selectedValues.includes('agent_admin')) {
            this.managedCustomersTarget.disabled = false
        } else {
            this.managedCustomersTarget.disabled = true
            $(this.managedCustomersTarget).val(null).trigger('change')
        }
    }
}
