export function initBasicKpiChart(metaData, seriesData) {
    const containerId = metaData.containerId
    const title = metaData.title || ""
    const crosshairSyncable = metaData.crosshairSyncable
    const zoomable = metaData.zoomable

    const series = JSON.parse(seriesData)

    const chartOptions = {
        chart: {
            zoomType: 'x',
            height: 250,
            events: {
                load: function () {
                    if (metaData.addLegendHeight) {
                        addLegendHeight(this);
                    }
                },
                selection: function (event) {
                    event = calculateZoomExtremes(event)
                    if (zoomable) {
                        dispatchEvent(new CustomEvent("notifyZoom", {detail: {event: event}}));
                    }

                }
            },
            resetZoomButton: {
                position: {
                    x: 0,
                    y: -30
                }
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                },
                step: metaData.lineStep,
                point: {
                    events: {
                        click: function () {
                            location.href = metaData.pointSelectLocation + moment(this.x).format('DD-MM-YYYY');
                        }
                    }
                }
            },
            series: {
                events: {
                    show: eventToggleAxisVisibility,
                    hide: eventToggleAxisVisibility
                },
                point: {
                    events: {
                        mouseOver: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: this}}));
                            }
                        },
                        mouseOut: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: null}}));
                            }
                        }
                    }
                }
            }
        },
        series: series,
        title: {
            text: `<div class='small fw-bold text-center text-uppercase text-body'>${title}</div>`,
            style: {
                fontSize: '15px'
            },
            useHTML: true
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            outside: true,
            formatter: function () {
                let tooltip = Highcharts.dateFormat('%A %b %e %Y', new Date(this.x));
                this.points.forEach(point => {
                    let point_value = ((point.y / 10) > 1) ? Math.round(point.y) : point.y
                    let unit = point.series.options.custom?.unit || ""
                    tooltip += '<br><span style="color:' + point.color + '">● </span>' + point.series.name + '</span>: ' + numberWithSeparator(point_value) + ' ' + unit;
                })
                return tooltip;
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value: %d/%m}'
            },
            tickPixelInterval: 50,
            plotLines: [
                {
                    color: 'red',
                    dashStyle: 'dash',
                    value: metaData.plotLine,
                    width: 1
                }
            ]
        },
        yAxis: [
            {
                min: 0,
                tickPositions: metaData.yTickPositions,
                title: {
                    text: undefined
                },
                gridLineColor: 'white'
            },
            {
                title: {
                    text: undefined
                },
                visible: false,
                opposite: true
            }
        ]
    }

    metaData?.yAxis?.forEach((data, index) => {
        chartOptions.yAxis[index].softMax = data.softMax
        chartOptions.yAxis[index].title.text = data.title
    })


    let chart = Highcharts.chart(containerId, chartOptions);

    if (zoomable) {
        dispatchEvent(new CustomEvent("registerZoomableChart", {detail: {chart: chart}}));
    }
    if (crosshairSyncable) {
        dispatchEvent(new CustomEvent("registerCrosshairSyncChart", {detail: {chart: chart}}));
    }
}
