import TaskPoller from '../plugins/poll_tasks';
import '../plugins/poll_messages';
// Debounce Function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}


// Ajax pagination
function ajax_pagination() {
  $('.pagination a').off('click').on('click', function(event) {
    event.preventDefault();
    $.ajax({type: 'GET',
      url:  $(this).attr('href'),
      dataType: 'script'
    });
    false
  });
}

function isIE(version, comparison){
  let $div = $('<div style="display:none;"/>').appendTo($('body'));
  $div.html('<!--[if '+(comparison||'')+' IE '+(version||'')+']><a>&nbsp;</a><![endif]-->');
  let ieTest = $div.find('a').length;
  $div.remove();
  return ieTest;
}


function get_ie_version() {
  let rv = -1; // Return value assumes failure.
  if (navigator.appName == 'Microsoft Internet Explorer') {
    let ua = navigator.userAgent;
    let re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat(RegExp.$1);
  }
  return rv;
}


function show_old_ie_warnings() {
  let ver = get_ie_version();
  if ((ver > 0 && ver < 9.0) || isIE(8, 'lte')) {
    $('.old_ie_warning').html('');
  }
}

function show_alerts(){
  // does not work, need refactoring
  // Install handler, which marks removed messages as read.
  $('.alert-notify').addClass('in').bind('close.bs.alert', function(obj) {
    let message    = $(this);
    let msg_id     = message.attr('data-msg-id');
    let alert_type = message.attr('data-alert-type');
    if(msg_id) {
      $.getScript('/messages/mark_read/' + msg_id);
    }
    if(alert_type) {
      $.getScript('/alerts/snooze/' + alert_type);
    }
  });
}

function update_message(msg_id){
  if(msg_id) {
    $.getScript('/messages/mark_read/' + msg_id);
  }
}


function render_datetimepicker() {
  $('.datetimepicker').datetimepicker();
}


function renderSmoothScroll() {
  $('.smooth-scroll, .text-filter').unbind('click').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
}


function install_turbo_for_forms() {
  $('form[method=get]:not([data-remote])').on('submit', function(event) {
    event.preventDefault();
    return Turbo.visit(this.action + '?' + $(this).serialize());
  });
}

// Search observer
function install_search_observer() {
  return $('.search-observer').keyup(function() {
    let form, group;
    group = $(this);
    form = group.parents('form').eq(0);
    $.get(form.attr('action'), form.serialize(), null, 'script');
    return false;
  });
}

function install_sortable_handler() {
  $('.sortable').sortable({
    update: function(e, ui) {
      $.post($(this).data('update-url'), $(this).sortable('serialize'));
      // console.log($(this).data('update-url'));
      // console.log($(this).sortable('serialize'));
    }
  });
  $('.sortable li.sm').addClass('col-md-3 col-sm-4 col-xs-12');
  $('.sortable li.lg').addClass('col-md-4 col-sm-4 col-xs-12');
  $('.sortable li.xl').addClass('col-md-12 col-sm-12 col-xs-12');
}


function init_nav_menu() {
  $('.nav .dropdown').hover(
    function(){ $(this).addClass('open'); },
    function(){ $(this).removeClass('open'); }
  );
}

function init_cookies_eu() {
  windowIsTurbolinked = false;
  cookiesEu.init();
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function check_user_agreed() {
  if ($('body').data('user-agreement-pending')) {
    $.ajax({
      type: 'GET',
      url: '/terms_and_conditions.js'
    });
  }
}

function init_datepicker() {

  // Variables
  let $datepicker = $('.datepicker');

  // Methods
  function init($this) {
    let options = {
      disableTouchKeyboard: false,
      autoclose: true,
      clearBtn: true
    };

    $this.datepicker(options);
  }

  // Events
  if ($datepicker.length) {
    $datepicker.each(function() {
      init($(this));
    });
  }
}

function smallNavbar() {
  const $pageContainer = $('#page-container');
  const $stickyTop = $pageContainer.find('.sticky-top');
  const $header = $pageContainer.find('.header');
  const $filters = $pageContainer.find('.filters');
  const $filtersForm = $filters.find('form');
  const $textFilter = $pageContainer.find('.filters .text-filter');
  const currentScroll = $(window).scrollTop();

  function updateStylesForSmallNavbar() {
    $stickyTop.addClass('small-navbar');

    if ($header.length) {
      $header[0].style.marginTop = `${$stickyTop.find('.container-fluid').height() - $filters.height()}px`;
    }

    $filtersForm.addClass('d-none');
    $textFilter.removeClass('d-none');
  }

  function resetStylesForNormalNavbar() {
    if ($header.length) {
      $header[0].style.marginTop = '0';
    }
    $stickyTop.removeClass('small-navbar');
    $filtersForm.removeClass('d-none');
    $textFilter.addClass('d-none');
  }

  if (currentScroll > 80) {
    if (!$stickyTop.hasClass('small-navbar')) {
      updateStylesForSmallNavbar();
    }
  } else if (currentScroll < 40) {
    if ($stickyTop.hasClass('small-navbar')) {
      resetStylesForNormalNavbar();
    }
  }
}


function checkScrollForPresentation() {
  let current_scroll = $(this).scrollTop();
  if(current_scroll > 80 ) {
    $('#scrollTopBtn').fadeIn();
  } else {
    $('#scrollTopBtn').fadeOut();
  }
}

function setCookie(name,value,days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)== '') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function set_action_performance_form(submit) {
  let auth_token = $('meta[name=csrf-token]').attr('content');
  $(document.multiple_options_performance_form).removeAttr("data-remote");
  $(document.multiple_options_performance_form).removeData("remote");

  switch (submit) {
    case 'Report':
      document.multiple_options_performance_form.action = '/performance/report.pdf?authenticity_token=' + auth_token;
      return true;
    case 'Csv':
      document.multiple_options_performance_form.action = '/performance/report.zip?type=csv&authenticity_token=' + auth_token;
      return true;
    case 'Png':
      document.multiple_options_performance_form.action = '/performance/report.zip?type=png&authenticity_token=' + auth_token;
      return true;
    case 'Compare':
      document.multiple_options_performance_form.action = '/performance/compare';
      document.multiple_options_performance_form.dataset.remote = 'true';
      return true;
    default:
      return false;
  }
}

function install_jq_handlers() {
  enable_bootstrap_tooltips();
  reflowHighcharts();

  window.dispatchEvent(new Event('select2ScrollNotifier'));
}

function enable_bootstrap_tooltips() {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

function increase_brightness(hex, percent){
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if(hex.length === 3){
    hex = hex.replace(/(.)/g, '$1$1');
  }

  let r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return '#' +
    ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
    ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
    ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

// TODO showFlash appears to also exist in notify_controller.
function showFlash(type, message, wait_for){
  // for details look: http://bootstrap-notify.remabledesigns.com/
  type = type === 'notice' ? 'info' : type;
  if (!wait_for) wait_for = 4000;

  $.notify({
    message: message
  },{
    type: type,
    delay: wait_for,
    allow_dismiss: true,
    placement: {
      from: 'top',
      align: 'left'
    },
    z_index: 9996,
    offset: { x: 20, y: 55 },
    template: '<div class="border-0 alert-dismissible alert alert-{0} fade show text-white col-auto" role="alert">' +
      '<span class="alert-icon">{1}</span> ' +
      '<span class="alert-text text-break">{2}</span>' +
      '<button type="button" class="btn-close fs-4 pt-2" data-bs-dismiss="alert" aria-label="Close">'+
      '<span aria-hidden="true">&times;</span>'+
      '</button>'+
      '</div>'
  });
}

function reflowHighcharts(){
  setTimeout(function () {
    $('[data-highcharts-chart]').each(function() {
      $(this).highcharts().reflow();
    });}, 300);
}

function number_to_human_size(size, precision) {
  if (size < 1024)
    return size + ' bytes';
  else if(size < 1024.0 * 1024.0)
    return (size / 1024.0).toFixed(precision) + ' KiB'
  else if(size < 1024.0 * 1024.0 * 1024.0)
    return (size / 1024.0 / 1024.0).toFixed(precision) + ' MiB'
  else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0)
    return (size / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' GiB'
  else if(size < 1024.0 * 1024.0 * 1024.0 * 1024.0 * 1024)
    return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' TiB'
  else
    return (size / 1024.0 / 1024.0 / 1024.0 / 1024.0 / 1024.0).toFixed(precision) + ' PiB';
}

function numberWithSpaces(x) {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}



// function enable_fixed_table_headers() {
//   let width = $(window).width();
//   // todo Fixed header not working when screen is sized for mobile (menu changed)
//   let focusElement = $('#' + document.activeElement.id)
//   if( width > 992 ) {
//     $("table.table-fixed-header").floatThead({
//       top: 77,
//       responsiveContainer: function($table){
//         return $table.closest(".table-responsive");
//       }
//     });
//     setTimeout(function () {
//       $("table.table-fixed-header").trigger("reflow");
//     }, 400);
//     setTimeout(function () {
//       focusElement.focus();
//     }, 500);
//   }
// }

// A function to flash a component text(eg after an update), to draw attention
function highlight_change(element) {
  let highlight_color = 'bg-highlight';

  element.addClass(highlight_color);

  setTimeout(function(element){
    element.removeClass(highlight_color);
  }, 2000, element);

  return this;
}

function numberWithSeparator(number, separator="'") {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

function calculateMidpoint(number1, number2) {
  let highNumber, lowNumber;
  if(number1 > number2) {
    highNumber  = number1;
    lowNumber   = number2;
  } else {
    highNumber  = number2;
    lowNumber   = number1;
  }

  return (highNumber + lowNumber) / 2;
}


window.debounce = debounce;
window.ajax_pagination = ajax_pagination;
window.isIE = isIE;
window.get_ie_version = get_ie_version;
window.show_old_ie_warnings = show_old_ie_warnings;
window.show_alerts = show_alerts;
window.update_message = update_message;
window.render_datetimepicker = render_datetimepicker;
window.renderSmoothScroll = renderSmoothScroll;
window.install_turbo_for_forms = install_turbo_for_forms;
window.install_search_observer = install_search_observer;
window.install_sortable_handler = install_sortable_handler;
window.init_nav_menu = init_nav_menu;
window.init_cookies_eu = init_cookies_eu;
window.capitalize = capitalize;
window.check_user_agreed = check_user_agreed;
window.init_datepicker = init_datepicker;
window.smallNavbar = smallNavbar;
window.checkScrollForPresentation = checkScrollForPresentation;
window.setCookie = setCookie;
window.getCookie = getCookie;
window.eraseCookie = eraseCookie;
window.set_action_performance_form = set_action_performance_form;
window.increase_brightness = increase_brightness;
window.install_jq_handlers = install_jq_handlers;
window.showFlash = showFlash;
window.reflowHighcharts = reflowHighcharts;
window.number_to_human_size = number_to_human_size;
window.numberWithSpaces = numberWithSpaces;
window.enable_bootstrap_tooltips = enable_bootstrap_tooltips;
window.highlight_change = highlight_change;
window.numberWithSeparator = numberWithSeparator;
window.calculateMidpoint = calculateMidpoint;

window.TaskPoller = TaskPoller;
window.performance_analyse_poller = new TaskPoller({url: '/performance/check_run_plot', ajax_global: false, poll_times_after_stop: 0, timeout: 10000});


$(document).on('turbo:load', function() {
  install_turbo_for_forms();
  install_search_observer();
  install_sortable_handler();
  // enable_fixed_table_headers();
  show_alerts();
  ajax_pagination();
  show_old_ie_warnings();
  renderSmoothScroll();
  init_cookies_eu();
  check_user_agreed();
  $(window).on('scroll',function(){
    checkScrollForPresentation();
    smallNavbar();
    $('.filters select').select2('close');
  });

  // new ScrollHint('.table-responsive', {
  //   suggestiveShadow: true,
  //   remainingTime: 0
  // });

  if (document.URL.match('welcome') != 'welcome' && document.URL.match('login') != 'login' && document.URL.match('users/password') != 'users/password') {
    if (!performance_analyse_poller.started) {
      performance_analyse_poller.start();
    }
  }
});
