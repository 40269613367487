/**
 * JS Class that initializes an element that is passed into the constructor of the class.
 *
 * Usage:
 * import { FixedScrollbarContainer } from '../custom_functions/fixed_scrollbar_container.js'
 *
 * new FixedScrollbarContainer(element)
 */
export class FixedScrollbarContainer {

  constructor(element) {
    let scrollbar = $('<div class="fixed-scrollbar-container-top"><div></div></div>').appendTo($(document.body));
    let lastScroll = {};
    scrollbar.hide().css({
      overflowX:'auto',
      position:'fixed',
      width:'100%',
      bottom:0,
      height: '10px'
    });
    let fakecontent = scrollbar.find('div');

    function top(e) {
      return e.offset().top;
    }

    function bottom(e) {
      return e.offset().top + e.height();
    }

    let active = $([]);
    function find_active() {
      scrollbar.show();
      let active = $([]);
      let find_class = element.classList.contains('datatable-filter') ? $(element).find('.dataTables_scrollBody') : $(element)
      find_class.each(function() {
        if (top($(this)) < top(scrollbar) && bottom($(this)) > bottom(scrollbar)) {
          fakecontent.width($(this).get(0).scrollWidth);
          fakecontent.height(1);
          active = $(this);
        }
      });
      fit(active);
      return active;
    }

    function fit(active) {
      let find_element = element.classList.contains('datatable-filter') ? $(element).find('.dataTables_scrollBody') : $(element)
      if (!active.length) return scrollbar.hide();
      scrollbar.css({left: active.offset().left, width:active.width()});
      fakecontent.width(find_element.get(0).scrollWidth);
      fakecontent.height(1);
      delete lastScroll.prototype;
    }


    function onscroll(){
      let oldactive = active;
      active = find_active();
      if (oldactive.not(active).length) {
        oldactive.unbind('scroll', update);
      }
      if (active.not(oldactive).length) {
        active.scroll(update);
      }
      update();
    }

    function scroll() {
      if (!active.length) return;
      if (scrollbar.scrollLeft() === lastScroll) return;
      lastScroll = scrollbar.scrollLeft();
      active.scrollLeft(lastScroll);
    }

    function update() {
      if (!active.length) return;
      if (active.scrollLeft() === lastScroll) return;
      lastScroll = active.scrollLeft();
      scrollbar.scrollLeft(lastScroll);
    }

    scrollbar.scroll(scroll);

    onscroll();
    $(window).scroll(onscroll);
    $(window).resize(onscroll);
  }
}