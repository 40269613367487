export function initIOProfilePieChart(metaData, seriesData) {
    const containerId = metaData.containerId
    const series = JSON.parse(seriesData)

    let chartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 250
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        series: series
    }
    let chart = Highcharts.chart(containerId, chartOptions);
}

export function initIOProfileHistoricChart(metaData, seriesData) {
    const containerId = metaData.containerId
    const crosshairSyncable = metaData.crosshairSyncable
    const zoomable = metaData.zoomable
    const series = JSON.parse(seriesData)

    const chartOptions = {
        chart: {
            type: 'area',
            height: 250,
            zoomType: 'x',
            events: {
                selection: function (event) {
                    event = calculateZoomExtremes(event)
                    if (zoomable) {
                        dispatchEvent(new CustomEvent("notifyZoom", {detail: {event: event}}));
                    }
                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value: %d/%m}'
            },
            crosshair: {
                zIndex: 4
            },
            plotLines: [
                {
                    color: 'red',
                    dashStyle: 'dash',
                    value: metaData.plotLine,
                    width: 1,
                    zIndex: 4
                }
            ]
        },
        yAxis: {
            title: {
                enabled: false
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            formatter: function () {
                // The first returned item is the header, subsequent items are the
                // points
                let date = Highcharts.dateFormat('%A, %b %e', new Date(this.x))
                return ['<b>' + date + '</b><br>'].concat(
                    this.points ?
                        this.points.map(function (point) {
                            let value = numberWithSeparator(Math.round(point.y));
                            return '<span style="color:' + point.color + '">● </span>' + point.series.name + '</span>: <b>' + value + ' (' + Math.round(point.point.custom.percentage) + '%)</b><br>';
                        }) : []
                );
            }
        },
        plotOptions: {
            area: {
                cursor: 'pointer',
                stacking: 'normal',
                lineColor: '#ffffff',
                lineWidth: 1,
                marker: {
                    enabled: false
                },
                point: {
                    events: {
                        click: function () {
                            location.href = "#{point_select_location}" + moment(this.x).format('DD-MM-YYYY');
                        }
                    }
                }
            },
            series: {
                point: {
                    events: {
                        mouseOver: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: this}}));
                            }
                        },
                        mouseOut: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: null}}));
                            }
                        }
                    }
                }
            }
        },
        series: series
    }

    let chart = Highcharts.chart(containerId, chartOptions);

    if (zoomable) {
        dispatchEvent(new CustomEvent("registerZoomableChart", {detail: {chart: chart}}));
    }
    if (crosshairSyncable) {
        dispatchEvent(new CustomEvent("registerCrosshairSyncChart", {detail: {chart: chart}}));
    }
}
