export function initThresholdsHistoricChart(metaData, seriesData) {
    const containerId = metaData.containerId
    const title = metaData.title || ""
    const crosshairSyncable = metaData.crosshairSyncable
    const zoomable = metaData.zoomable

    const series = JSON.parse(seriesData)

    const chartOptions = {
        chart: {
            events: {
                load: function () {
                    addLegendHeight(this);
                },
                selection: function (event) {
                    event = calculateZoomExtremes(event)
                    if (zoomable) {
                        dispatchEvent(new CustomEvent("notifyZoom", {detail: {event: event}}));
                    }
                }
            },
            height: 250,
            resetZoomButton: {
                position: {
                    x: 0,
                    y: -30
                }
            },
            type: 'area',
            zoomType: 'x'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                },
                point: {
                    events: {
                        click: function () {
                            location.href = metaData.pointSelectLocation + moment(this.x).format('DD-MM-YYYY');
                        }
                    }
                }
            },
            series: {
                cursor: 'pointer',
                marker: {
                    enabled: false
                },
                point: {
                    events: {
                        mouseOver: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: this}}));
                            }
                        },
                        mouseOut: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: null}}));
                            }
                        }
                    }
                }
            }
        },
        series: series,
        title: {
            text: `<div class='small fw-bold text-center text-uppercase text-body'>${title}</div>`,
            style: {
                fontSize: '15px'
            },
            useHTML: true
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            outside: true,
            formatter: function () {
                // The first returned item is the header, subsequent items are the
                // points
                let date = Highcharts.dateFormat('%A, %b %e', new Date(this.x))
                return ['<b>' + date + '</b><br>'].concat(
                    this.points ?
                        this.points.map(function (point) {
                            return point.series.options.custom.description + ': ' + point.y + '<br>';
                        }) : []
                );
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value: %d/%m}'
            },
            tickPixelInterval: 50,
            plotLines: [
                {
                    color: 'red',
                    dashStyle: 'dash',
                    value: metaData.plotLine,
                    width: 1
                }
            ]
        },
        yAxis:
            {
                min: 0,
                softMax: 300,
                title: {
                    text: metaData.yAxis[0].title
                },
                gridLineColor: 'white'
            }
    }

    let chart = Highcharts.chart(containerId, chartOptions);

    if (zoomable) {
        dispatchEvent(new CustomEvent("registerZoomableChart", {detail: {chart: chart}}));
    }
    if (crosshairSyncable) {
        dispatchEvent(new CustomEvent("registerCrosshairSyncChart", {detail: {chart: chart}}));
    }
}

export function initThresholdsBarChart(metaData, seriesData) {
    const containerId = metaData.containerId

    const series = JSON.parse(seriesData)

    const chartOptions = {
        chart: {
            type: 'column',
            height: 250,
            events: {
                load: function () {
                    addLegendHeight(this);
                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: JSON.parse(metaData.categories)
        },
        yAxis: {
            min: 0,
            title: {
                text: metaData.yAxisTitle
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: series
    }

    let chart = Highcharts.chart(containerId, chartOptions);
}
