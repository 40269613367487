import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let element = this.element;
    this.initHighChart(element)
  }

  initHighChart(element) {

    let xAxisTitle = element.dataset['xAxisTitle'];
    let yAxisTitle = element.dataset['yAxisTitle'];
    let container = element.dataset['container'];
    let seriesData = JSON.parse(element.dataset['seriesData']);

    new Highcharts.Chart(container, {
      chart: {
        type: "bubble",
        zoomType: "xy"
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      xAxis: {
        title: {
          text: xAxisTitle
        },
        type: "linear",
        min:0.0
      },
      yAxis: {
        title: {
          text: yAxisTitle
        },
        type: "linear",
        min: -2.0
      },
      tooltip: {
        formatter: function () {
          return '<span style="font-weight:bold;color:#fff">' + this.point.custom.secondaryName + '</span><br/>' +
            '<span style="font-style:italic;color:#fff">' + this.point.name + '</span><br/>' +
            '<span style="color:#fff">AVG TPUT: <span style="color:#fff;font-weight:bold;">' + this.x + 'MiB/sec</span></span><br/>' +
          '<span style="color:#fff">AVG SVCT: <span style="color:#fff;font-weight:bold;">' + this.y + ' ms</span></span><br/>' +
          '<span style="color:#fff;">WTIM: ' + this.point.custom.wtim + ' sec, Perf. Index: ' + this.point.custom.perfIndex + 'TiB/sec&sup2</span>'
        }
      },
      series: seriesData
    });

  }
}