export class DatatableAsyncPopulation{
    constructor(dataTable, ajaxUrlPath, tableId) {
        switch (tableId){
            case 'ldev-cluster-luns-table':
                ldevClusterLunTableData();
                break;
            default:
                console.log('Table Id not found');
        }

        function ldevClusterLunTableData() {
            $.ajax({
                url: ajaxUrlPath,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    data.forEach(function (obj) {
                        dataTable.cell(`#row-${obj.id}`, 5).data(obj.used);
                        dataTable.cell(`#row-${obj.id}`, 6).data(obj.total);
                        dataTable.cell(`#row-${obj.id}`, 7).data(obj.used_percentage);
                    });
                }
            });
        }
    }

}