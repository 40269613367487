import {Controller} from "@hotwired/stimulus"
import {sortData, updateChartSorting} from "../../custom_functions/charts/kpi_comparison_shared";

export default class extends Controller {

    connect() {
        super.connect();
        this.isSortAscending = true
        this.currentSortMetric = null

        this.meta = JSON.parse(this.element.dataset['chartMeta'])
        this.seriesData = JSON.parse(this.element.dataset['seriesData'])

        this.sortedData = sortData(this.seriesData, this.currentSortMetric, this.isSortAscending)

        this.chart = this.initCapacityOverviewChart()

        if (!hasData(this.sortedData)) {
            let noDataText = this.meta.noData
            this.chart.hideNoData()
            this.chart.showNoData(noDataText)
        }
    }

    changeSorting(event) {
        if (event.params.metric !== undefined) {
            this.currentSortMetric = event.params.metric
        }
        if (event.params.ascending !== undefined) {
            this.isSortAscending = event.params.ascending
        }

        updateChartSorting(this.chart, this.seriesData, this.currentSortMetric, this.isSortAscending)
    }

    initCapacityOverviewChart() {
        const containerId = this.element.dataset['containerId']

        const chartOptions = {
            chart: {
                type: 'column',
                events: {
                    load: function () {
                        if (this.series[0].data.length > paginationOptions.defaultSize) {
                            this.xAxis[0].update({min: 0, max: set_columns(paginationOptions.defaultSize)})
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        y: -10,
                        style: {
                            fontSize: '14px'
                        },
                        formatter: function () {
                            if (this.series && this.series.type === 'scatter') {
                                return null
                            } else {
                                if (this && this.point && this.point.custom && this.point.custom.percentage) {
                                    return this.point.custom.percentage + '%'
                                } else {
                                    return null
                                }
                            }
                        }
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    let tooltip = '<span style="color: #06A50C">● </span><b>'
                    if (this.series && this.series.type === 'scatter') {
                        tooltip += '<span><b>' + this.point.name + '</b></span><br/>'
                        tooltip += '<span>' + this.series.name + ': </span>'
                        tooltip += '<span class="font-weight-normal">' + this.point.custom.tooltip + '</span><br/>'
                    } else {
                        tooltip += '<span><b>S/N: ' + this.points[0].key + '</b></span><br/>'
                        this.points.forEach((element) => {
                            tooltip += '<span>' + element.series.name + ': </span>'
                            tooltip += '<span class="font-weight-normal">' + element.point.custom.tooltip + '</span><br/>'
                        })
                    }
                    return tooltip
                },
                shared: true
            },
            xAxis: {
                type: 'category',
                labels: {
                    autoRotation: [0, -90]
                }
            },
            yAxis: {
                title: {
                    text: this.meta.yAxis
                }
            },
            series: this.sortedData
        }

        return Highcharts.chart(containerId, chartOptions);
    }
}
