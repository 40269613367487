import "../plugins/moment"
import "../plugins/jszip"
require( 'datatables.net' )( window, $ );
require( 'datatables.net-fixedheader' )( window, $ );
require( 'datatables.net-bs5' )( window, $ );
require( 'datatables.net-buttons' )( window, $ );
require( 'datatables.net-buttons/js/buttons.html5' )( window, $ );
require( 'datatables.net-buttons-bs5/js/buttons.bootstrap5' )( window, $ );
require( 'datatables.net-plugins/sorting/any-number' )
require( 'datatables.net-plugins/sorting/file-size' )
require('datatables.net-plugins/sorting/datetime-moment')