import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  openModal() {
    let element = this.element;
    let content = element.dataset['content'];
    $('#modal-container').html(decodeURIComponent(content));
    $('#import-modal').modal('show');
  }

  submitFormLuns() {
    let element = this.element;
    let fileInput = document.getElementById('import_luns')
    let file = fileInput.files[0];
    let form_id = element.dataset['formId'];
    let form = document.getElementById(form_id);
    let typeForm = form.dataset['typeForm'];
    let newForm = new FormData(form)
    if (file) {
      if (file.type === 'text/csv') {
        $(form).attr('enctype', 'multipart/form-data');
        $(form).attr('remote', 'true');
        newForm.append(fileInput.name, file, file.name);
        newForm.append('type_form', typeForm);

        if($(element).closest('#import-modal').length > 0) {
          $(element.closest('#import-modal')).modal('hide');
        }

        $.ajax({url: $(element).attr('data-ajax-form-action'),
          type: 'post',
          data: newForm,
          processData: false,
          contentType: false,
          dataType: 'script'
        });
      } else {
        $(element).parent().parent().find('.invalid-feedback').addClass('d-block').html('Please select a csv file');
      }

    } else {
      $(element).parent().parent().find('.invalid-feedback').addClass('d-block').html('Please select a file');
    }
  }
}