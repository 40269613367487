import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["grid"]

    chartReady() {
        new Masonry(this.gridTarget, {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            horizontalOrder: true
        });
    }
}
