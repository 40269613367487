import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh"
export default class extends Controller {
  static values = {
    updateUrl: String,
    intervalInSecs:   { type: Number, default: 20  /* 20 seconds */ }
  }

  connect() {
    this.refreshId = this.setRefreshTimeout()
    this.initProgressLoader()
  }

  disconnect() {
    clearTimeout(this.refreshId)
  }

  initProgressLoader() {
    let progressBar = document.getElementById('progress-bar');
    let duration = this.intervalInSecsValue; // Duration in seconds
    let step = 1000; // Step in milliseconds (1 second = 1000 milliseconds)
    let widthIncrement = 100 / ((duration * 1000 - step) / step);
    let width = 0;

    let interval = setInterval(function () {
      width += widthIncrement;
      if (width <= 100) {
        progressBar.style.width = width + '%';
        progressBar.setAttribute('aria-valuenow', width);
      } else {
        clearInterval(interval);
      }
    }, step);
  }


  setRefreshTimeout() {
    return setTimeout(() => {
      this.refresh()
    }, this.intervalInSecsValue * 1000)
  }

  refresh() {
    if(document.hidden) {
      this.refreshId = this.setRefreshTimeout()
    } else {
      $.ajax({
        type: 'GET',
        url: this.updateUrlValue,
        dataType: 'script'
      });
    }
  }
}
