import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

// Send Form on change as AJAX request
  install_onchange_ajax_send_handler() {
    let group = $(this.element);
    let name = this.element.name;

    if (group.attr('data-ajax-form-action')) {
      if (group.hasClass('select2-hidden-accessible'))
        group.select2('close')
      let form = group.parents('form').eq(0);
      let typeMethod = group.attr('data-type-method') || 'GET';
      $.ajax({
        type: typeMethod,
        url: group.attr('data-ajax-form-action'),
        data: form.serialize() + '&element=' + name,
        dataType: 'script'
      });
    }
  }

  // Send Form on click as AJAX request
  install_onclick_ajax_send_handler() {
    let group = $(this.element);
    let name = this.element.name;
    let form  = group.parents('form').eq(0);
    let typeMethod = group.attr('data-type-method') || 'GET';
    $.ajax({
      type: typeMethod,
      url: group.attr('data-ajax-form-action'),
      data: form.serialize() + '&element=' + name,
      dataType: 'script'
    });
  }

  formSubmitOnAction() {
    let group = $(this.element);
    let form  = group.parents('form').eq(0);

    $.ajax({
      url:    form.attr('action'),
      method: form.attr('method'),
      data:   form.serialize(),
      dataType: 'script'
    });
  }

  removeRow() {
    let element = this.element;

    $(element).parents('.remove-row').fadeOut(100, function () {
      $(this).empty();
      return false;
    });
  }
}
