import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initMagnificPopup(this.element)
  }

  initMagnificPopup(element){
    let errorMessage = element.dataset['errorMessage']

    $('#gallery_overview .turbo-gallery').magnificPopup({
      delegate: 'a.gallery-group',
      type: 'ajax',
      tLoading: '<div class="spinner-dots-container"><div></div></div>',
      gallery: {
        enabled: true
      },
      ajax: {
        tError: errorMessage
      },
      closeOnBgClick: false,
      callbacks: {
        elementParse: function(item) {
          // Function will fire for each target element
          // "item.el" is a target DOM element (if present)
          // "item.src" is a source that you may modify
          item.src += '&width=' + $(window).width() + '&height=' + $(window).height();
        },
        change: function() {
          cleanUpHighcharts()
        },
        close: function() {
          cleanUpHighcharts()
        },
        parseAjax: function (mfpResponse) {
          // append mfp-counter in the response
          let $index = this.index + 1,
            $total = this.items.length;
          mfpResponse.data = mfpResponse.data += '<div class="mfp-counter"> '+ $index + ' of ' + $total + '</div>';
        },
        ajaxContentAdded: function() {
          // Ajax content is loaded and appended to DOM
          $('#gallery_overview .includeCheckbox').each(function() {
            var cb_cmd = false;
            if (this.checked)
              cb_cmd = true;
            $('#caption_' + this.id).prop('checked', cb_cmd);
          });

          $('.mfp-content .includeCheckbox').unbind('change').change( function() {
            var include_id = this.id.replace(/^caption_/, '');
            var cb_cmd = false;
            if (this.checked)
              cb_cmd = true;
            $('#' + include_id).prop('checked', cb_cmd).change();
          });

          $('#gallery_overview .comment-text').unbind('change').each(function() {
            var comment_id = 'caption_' + this.id;
            var text_field = $('#' + comment_id);
            if (text_field)
              text_field.prop('value', this.value);
          });

          $('.mfp-content .comment-text').unbind('change').change(function() {
            var comment_id = this.id.replace(/^caption_/, '');
            var hidden_field = $('#' + comment_id);
            if (hidden_field)
              hidden_field.prop('value', this.value);
          });
        }
      }
    });
  }

  selectAll(event) {
    let cb_cmd = false;
    if (event.target.checked)
      cb_cmd = true;
    $('input.includeCheckbox').prop('checked', cb_cmd);
    if ($('#performance-gallery-overview input.includeCheckbox:checkbox:checked').length === 2) {
      $('#report_selector button#report_compare_submit').prop('disabled', false);
    } else {
      $('#report_selector button#report_compare_submit').prop('disabled', true);
    }
    $('#report_selector button:not(#report_compare_submit)').prop('disabled', !cb_cmd);
  }

  updateGraphSelection() {
    switch ($('#performance-gallery-overview input.includeCheckbox:checkbox:checked').length) {
      case 0:
        $('#report_selector button').prop('disabled', true);
        break;
      case 2:
        $('#report_selector button').prop('disabled', false);
        break;
      default:
        $('#report_selector button#report_compare_submit').prop('disabled', true);
        $('#report_selector button:not(#report_compare_submit)').prop('disabled', false);
    }
  }
}