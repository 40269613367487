/**
 * Task poller task used for calling to a url with an ajax request every n milliseconds
 *
 * Use the method start() to start the poller, and stop() to stop the poller.
 *
 * @param params
 *          url: Required. The url path of the Ajax request
 *          timeout: Optional, default set to 5000 milliseconds. Poll every this number of milliseconds.
 *          poll_times_after_stop: Optional default 3. Number of times to poll after the signal stop was sent
 *          ajax_global: Optional default true. Used when the global ajax function ajaxStart() will be called or not.
 *                       By default it's called, which means if any spinner was set for global ajax use it will be visible.
 * @constructor
 */
export default class TaskPoller {
  constructor(params) {
    this.enabled = false;
    this.started = false;
    this.poll_ctr = -1;
    this.url = params.url;
    this.params = '';
    this.timeout = (typeof params.timeout === 'undefined') ? 5000 : params.timeout;
    this.poll_times_after_stop = (typeof params.poll_times_after_stop === 'undefined') ? 3 : params.poll_times_after_stop;
    this.timeout_handler = null;
    this.ajax_global = params.ajax_global === undefined ? true : params.ajax_global;
  }

  ////
  // Poll function. Gets called every 'timeout' milliseconds. If started will be false, it polls some times more.
  poll() {
    if (this.enabled)
      $.ajax({type: 'GET',
              url:  this.url + '?' + this.params,
              dataType: 'script'
      });

    // Reset poll_ctr
    if (this.started)
      this.poll_ctr = this.poll_times_after_stop;

    // Poll some more times if the signal for stopping was sent
    if (this.poll_ctr >= 0) {
      this.poll_ctr--;
      var that = this;
      this.timeout_handler = setTimeout(function () {
        that.poll();
      }, this.timeout);
    }
  }


  ////
  // Start task polling
  start() {
    this.started = true;
    this.enabled = true;
    if (this.poll_ctr < 0)
      this.poll();
  };


  ////
  // Stop task polling. Poll 'poll_times_after_stop' times after the stop signal was sent
  stop() {
    this.started  = false;
    if (this.poll_ctr <= 0) {
      clearTimeout(this.timeout_handler);
      this.poll_ctr = -1;
    }
  };


  ////
  // Enable Task-Poller
  enable() {
    this.enabled = true;
  };


  ////
  // Disable Task-Poller. Stop polling immediately
  disable() {
    clearTimeout(this.timeout_handler);
    this.enabled  = false;
    this.poll_ctr = -1;
  };


  ////
  // Set parameters for polling
  set_poll_params(params) {
    this.params = params;
  };
}
