import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="password-peek"
export default class extends Controller {
  static targets = [ "passwordField" ]
  connect() {
    let context = this;
    this.passwordPeek = this.togglePasswordPeek.bind(this);

    $(() => {
      $(context.passwordFieldTarget).on("click", context.passwordPeek);
    });

  }
  togglePasswordPeek(event) {
    let container = $(event.target).closest("div.input-container")
    let iconElement = container.find(".password_input_icon")
    let inputElement = container.find("input")

    if (inputElement.attr("type") === "password") {
      inputElement.attr("type", "text")
      iconElement.removeClass("fa-eye-slash")
      iconElement.addClass("fa-eye")
    } else {
      inputElement.attr("type", "password")
      iconElement.removeClass("fa-eye")
      iconElement.addClass("fa-eye-slash")
    }
  }
}
