import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
    let that = this;
    this.showFlash(that.element)
  }

  showFlash(element){
    let type = element.dataset['type'];
    let message = element.dataset['message'];
    let wait_for = element.dataset['wait'];

    // for details look: http://bootstrap-notify.remabledesigns.com/
    if (!wait_for) wait_for = 3000;
    if (wait_for === -1) wait_for = 30000;

    $.notify({
      icon: "",
      message: message
    },{
      type: type,
      timer: wait_for,
      allow_dismiss: true,
      placement: {
        from: 'top',
        align: 'left'
      },
      z_index: 1051,
      offset: { x: 20, y: 55 },
      template: '<div class="border-0 alert-dismissible alert alert-{0} fade show text-white col-auto" role="alert">' +
        '<span class="alert-icon">{1}</span> ' +
        '<span class="alert-text">{2}</span>' +
        '<button type="button" class="btn-close fs-4 pt-2" data-bs-dismiss="alert" aria-label="Close">'+
        '<span aria-hidden="true">&times;</span>'+
        '</button>'+
        '</div>'
    });
  }
}