import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    connect() {
        let that = this;

        that.element.addEventListener('change', function(e) {
            that.installTurboForForms(that.element);
        })
    }
    installTurboForForms(element) {
        return Turbo.visit(element.action + '?' + $(element).serialize());
    }
}