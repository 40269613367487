export function initAnomaliesChart(metaData, seriesData) {
    const safe_threshold = 2
    const warning_threshold = 6
    const danger_threshold = 10

    const containerId = metaData.containerId
    const title = metaData.title || ""
    const datePlotLine = metaData.plotLine
    const noData = metaData.noDataText
    const crosshairSyncable = metaData.crosshairSyncable
    const zoomable = metaData.zoomable
    const pointSelectLocation = metaData.pointSelectLocation
    const kpiUnit = metaData.unit

    const series = JSON.parse(seriesData)

    const chartOptions = {
        chart: {
            zoomType: 'x',
            height: 250,
            events: {
                selection: function(event) {
                    event = calculateZoomExtremes(event)
                    if(zoomable){
                        dispatchEvent(new CustomEvent("notifyZoom", {detail: {event: event}}));
                    }
                }
            },
            resetZoomButton: {
                position: {
                    x: 0,
                    y: -31
                }
            }
        },
        title: {
            text: `<div class='small fw-bold text-center text-uppercase text-body'>${title}</div>`,
            style: {
                fontSize: '15px'
            },
            useHTML: true
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dashStyle: 'dash',
                step: 'center',
                marker: {
                    enabled: false
                },
                point: {
                    events: {
                        click: function () {
                            location.href = pointSelectLocation + moment(this.x).format('DD-MM-YYYY');
                        }
                    }
                }
            },
            series: {
                cursor: 'pointer',
                color: "red",
                lineWidth: 1,
                enableMouseTracking: false,
                point: {
                    events: {
                        mouseOver: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: this}}));
                            }
                        },
                        mouseOut: function () {
                            if (crosshairSyncable) {
                                dispatchEvent(new CustomEvent("notifyCrossSync", {detail: {chart: this.series.chart, crosshairData: null}}));
                            }
                        }
                    }
                }
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value: %d/%m}'
            },
            tickPixelInterval: 50,
            plotLines: [
                {
                    color: 'red',
                    dashStyle: 'dash',
                    value: datePlotLine,
                    width: 1
                }
            ]
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: 'white',
            tickPositions: [-1, 0, safe_threshold, warning_threshold, danger_threshold],
            plotBands: [
                {
                    color: 'rgba(6, 178, 14, 0.5)',
                    from: 0,
                    to: safe_threshold
                },
                {
                    color: 'rgba(255, 170, 51, 0.5)',
                    from: safe_threshold,
                    to: warning_threshold
                },
                {
                    color: 'rgba(245, 73, 20, 0.5)',
                    from: warning_threshold,
                    to: danger_threshold
                }]
        },
        tooltip: {
            crosshairs: true,
            outside: true,
            formatter: function() {
                let tooltip = Highcharts.dateFormat('%A %b %e %Y', new Date(this.x));
                let pointValue = ((this.y / 10) > 1) ? Math.round(this.y) : this.y

                if(pointValue === -1) {
                    tooltip += '<br><span style="color:' + this.point.color + '">● </span>' + this.series.name + '</span>: ' + noData;
                } else {
                    tooltip += '<br><span style="color:' + this.point.color + '">● </span>' + this.series.name + '</span>: ' + numberWithSeparator(pointValue) + ` ${kpiUnit}`;
                }

                return tooltip;
            }
        },
        series: series
    }

    let chart = Highcharts.chart(containerId, chartOptions);

    if(zoomable){
        dispatchEvent(new CustomEvent("registerZoomableChart", {detail: {chart: chart}}));
    }
    if(crosshairSyncable){
        dispatchEvent(new CustomEvent("registerCrosshairSyncChart", {detail: {chart: chart}}));
    }

}


