import "../plugins/jquery"
import "daterangepicker"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    let that = this;
    this.initDateRangePicker(that.element)
  }

  initDateRangePicker(element) {
    moment.updateLocale('en', {
      week: {
        dow: 1 //Monday
      }
    });

    if (element.querySelector('.date-range-picker-with-time')){
      let input = element.querySelector('.date-range-picker-with-time')
      let startDate = input.dataset['startDate'] || false
      let endDate = input.dataset['endDate'] || false
      let availableDates = input.dataset['availableDates']
      let availableDatesArray = availableDates ? JSON.parse(availableDates) : []
      $(input).on('apply.daterangepicker', function(ev, picker) {
        $('#quick-datetime-from').val(picker.startDate.format("DD.MM.YYYY HH:mm"))
        $('#quick-datetime-to').val(picker.endDate.format("DD.MM.YYYY HH:mm"))
        $('#quick-daterangepicker-range').val(picker.startDate.format("DD.MM.YYYY HH:mm") + ' - ' + picker.endDate.format("DD.MM.YYYY HH:mm"));
      });

      let momentNow = moment().utc()

      $(input).daterangepicker({
        startDate: startDate,
        endDate: endDate,
        alwaysShowCalendars: true,
        timePicker: true,
        timePicker24Hour: true,
        opens: "left",
        autoApply: true,
        locale: {
          format: 'DD.MM.YYYY HH:mm'
        },
        ranges: {
          'Today so far':       [moment().startOf('day'),                                           moment().utc()],
          'Last 24 hours':      [moment().utc().subtract(24, 'hours'),                            moment().utc()],
          'Yesterday':          [moment().subtract(1, 'days').startOf('day'),           moment().subtract(1, 'day').endOf('day')],
          'Last weekend':       [moment().subtract(1, 'week').day(6).startOf('day'), moment().subtract(1, 'week').day(7).endOf('day')],
          'This week so far':   [moment().startOf( 'week'),                                         moment().utc()],
          'This month so far':  [moment().startOf( 'month').startOf('days'),              moment().utc()],
          'This year so far':   [moment().startOf('year').startOf('days'),                moment().utc()]
        },
        isInvalidDate: function(date) {
          // Convert date to a string in YYYY-MM-DD format for comparison
          let dateString = date.format('YYYY-MM-DD');
          // Check if the date is in the list of availableDatesArray
          if (availableDatesArray.length === 0) {
            return false;
          } else {
            return !availableDatesArray.includes(dateString);
          }
        }
      });
    } else if (element.querySelector('.date-time-picker')){
      let input = element.querySelector('.date-time-picker')
      $(input).daterangepicker({
        autoUpdateInput: false,
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
        startDate: moment().startOf('hour'),
        endDate: moment().endOf('hour'),
        locale: {
          format: 'DD-MM-YYYY HH:mm'
        }
      });
    } else if (element.querySelector('.date-picker')){
      let input = element.querySelector('.date-picker')
      let formId = element.dataset['formId'] || false
      let autoApply = element.dataset['autoApply'] || true
      let autoUpdateInput = input.dataset['autoUpdateInput'] || false
      let startDate = input.dataset['startDate'] || false
      let endDate = input.dataset['endDate'] || false
      let selectedDate = input.dataset['selectedDate'] || false
      let todayText = input.dataset['todayText']
      let yesterdayText = input.dataset['yesterdayText']
      let availableDates = input.dataset['availableDates']
      let availableDatesArray = availableDates ? JSON.parse(availableDates) : []
      let options = {
        minDate: startDate,
        maxDate: endDate,
        startDate: selectedDate,
        autoApply: autoApply,
        autoUpdateInput: autoUpdateInput,
        singleDatePicker: true,
        timePicker: false,
        cancelButtonClasses: 'm-0 w-100',
        ranges: {},
        showCustomRangeLabel: false,
        locale: {
          format: 'DD.MM.YYYY',
          cancelLabel: 'Clear'
        },
        isInvalidDate: function(date) {
          // Convert date to a string in YYYY-MM-DD format for comparison
          let dateString = date.format('YYYY-MM-DD');
          // Check if the date is in the list of availableDatesArray
          if (availableDatesArray.length === 0) {
            return false;
          } else {
            return !availableDatesArray.includes(dateString);
          }
        }
      }
      if (todayText)
        options.ranges[todayText] = [moment()]
      if (yesterdayText)
        options.ranges[yesterdayText] = [moment().subtract(1, 'days')]

      $(input).daterangepicker(options).on('cancel.daterangepicker', function() {
        setDateRangepickerDate(input, formId, moment())
      }).on('apply.daterangepicker', function(event, picker) {
        setDateRangepickerDate(input, formId, picker.startDate)
      });
    }

    function setDateRangepickerDate(input, formId, value) {
      $(input).val(value.format("DD.MM.YYYY"));
      if (formId) {
        $(`#${formId}`).submit();
        dateFormatter(input, value);
      }
    }

    function dateFormatter(input, selectedDate){
      let todayText = input.dataset['todayText'] || '';
      let yesterdayText = input.dataset['yesterdayText'] || '';

      if (moment().startOf('day').isSame(selectedDate.startOf('day'))) {
        $(input).val(todayText);
      } else if (moment().subtract(1, 'days').startOf('day').isSame(selectedDate.startOf('day'))) {
        $(input).val(yesterdayText);
      } else {
        $(input).val(selectedDate.format('DD.MM.YYYY'));
      }
    }
  }
}