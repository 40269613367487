var timeoutId;
function submitOnSelectWithDebounce($select, parentElement) {
  clearTimeout(timeoutId);
  var values = $select.select2('data').map(function(o) { return o.id });
  if ($select.attr('id') === 'instances' && values.length !== 1){
    if ($('#workplaces').length > 0 )
      $('#workplaces').val(['all'])
  }

  if (($select.attr('id') === 'workplaces' && values.length === 0 )) {
    $('#workplaces').val(['all']).trigger('change')
  }

  var form = $select.parents('form').eq(0);
  timeoutId = setTimeout(function() {
    $('.overlay').show();
    if ($select.attr('id') === 'workplaces') {
      $('#availableTimeRange').html('<i class="fad fa-spinner-third fa-spin ms-1 text-primary"></i>');
    } else {
      if (parentElement) startSpinner(parentElement);
    }
    let typeMethod = $select.attr('data-type-method') || "GET";
    $.ajax({
      type: typeMethod,
      url: $select.attr('data-ajax-form-action'),
      data: form.serialize(),
      dataType: 'script'
    });
  }, 1000);
}

function startSpinner(element){
  element.block({
    message: '<div class="spinner-dots-container"><div></div></div>',
    css: {
      top: (element.height() - 50) / 2 + 'px',
      left: (element.width() - 50) / 2 + 'px',
      width: '50px',
      border: 'none',
      padding: '1px',
      opacity: 1,
      cursor: 'default',
      color: '#0CA4A5',
      zIndex: 99999,
      backgroundColor: ''
    },
    showOverlay: false
  });
  element.css({
    cursor: 'progress'
  });
}

// Fixes select2 elements being misaligned when page becomes too populated and adds a window scrollbar
// (See #968 Performance drop-downs render broken on initial load )
function notifySelect2ToResize() {
  window.dispatchEvent(new Event('scroll'));
}


window.submitOnSelectWithDebounce = submitOnSelectWithDebounce;
window.startSpinner = startSpinner;
window.notifySelect2ToResize = notifySelect2ToResize;
