import "jquery-blockui"

function ajaxStart() {
  if (! $('body').data('ignoreGlobalAjaxSpinner')) {
    $.blockUI({
      message: '<div class="spinner-dots-container"><div class="spinner-box"><div class="spinner"></div></div></div>',
      css: {
        top: ($(window).height() - 130) / 2 + 'px',
        left: ($(window).width() - 250) / 2 + 'px',
        width: '1px',
        border: 'none',
        padding: '1px',
        opacity: 1,
        cursor: 'default',
        // color: '#0CA4A5',
        zIndex: 99999,
        backgroundColor: ''
      },
      showOverlay: false
    });
    $('body').css({
      cursor: 'progress'
    });
  }
}

function ajaxStop() {
  $.unblockUI();
  $('body').css({ cursor: 'auto' });
}

window.ajaxStart = ajaxStart
window.ajaxStop = ajaxStop

$(document).ajaxStart(ajaxStart).ajaxStop(ajaxStop);
$(document).on("turbo:before-visit", ajaxStart);