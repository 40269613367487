import {Controller} from "@hotwired/stimulus";


export default class extends Controller {
  connect() {
    let links = document.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute('disabled', 'disabled');
      links[i].removeAttribute('href');
      links[i].style.pointerEvents = 'none';
    }
  }
}